import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

interface InvoiceListItemIdProps extends Pick<TypographyProps, 'sx'> {
  id?: string
}

const InvoiceListItemId: FC<InvoiceListItemIdProps> = ({
  id,
  sx
}) => {
  return (
    <Typography
      variant="body2"
      sx={sx}
      color="text.secondary"
    >
      № {id}
    </Typography>
  );
};

export default InvoiceListItemId;