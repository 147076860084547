import { DeviceInfo } from '../@types/deviceInfo';
import { ClientJS } from 'clientjs';

export const device = new ClientJS();

export const deviceInfo: DeviceInfo = {
  browser: device.getBrowser(),
  browserVersion: device.getBrowserVersion(),
  id: device.getFingerprint().toString(),
  os: device.getOS(),
  osVersion: device.getOSVersion(),
  isMobile: device.isMobile(),
  isFirefox: device.isFirefox(),
};