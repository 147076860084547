import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../config';
import { deviceInfo } from './deviceInfo';
import { addNotificationsFromApiResponse } from './notificationUtils';
import {
  ApiRefreshTokenHandler,
  ApiResponse,
  ApiResponseAction,
  ApiResponseHandlersByAction,
  ApiResponseMessage,
  ApiResponseMessageType,
} from '../@types/api';
import useAuthStore from '../store/auth/auth.store';
import { AuthApi } from '../store/auth/auth.api';
import { appLang } from '../locales/i18n';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    "X-Device-Id": deviceInfo.id,
    "X-Device-OS": deviceInfo.os,
    "X-Device-OS-Version": deviceInfo.osVersion,
    "X-Browser": deviceInfo.browser,
    "X-Browser-Version": deviceInfo.browserVersion,
  },
});

const refreshTokenHandler: ApiRefreshTokenHandler = {
  currentRequest: null,
  refreshToken: async (): Promise<boolean> => {
    if (!refreshTokenHandler.currentRequest) {
      refreshTokenHandler.currentRequest = AuthApi
        .refreshToken()
        .then(response => {
          refreshTokenHandler.currentRequest = null;

          return response;
        });
    }

    return refreshTokenHandler.currentRequest;
  }
};

const responseHandlersByAction: ApiResponseHandlersByAction = {
  [ApiResponseAction.verification]: async (
    response: ApiResponse
  ): Promise<ApiResponse | null> => {
    useAuthStore.getState().setVerified(false);

    return response;
  },

  [ApiResponseAction.logout]: async (
    response: ApiResponse
  ): Promise<ApiResponse | null> => {
    await useAuthStore.getState().logout();

    return response;
  },

  [ApiResponseAction.refresh_token]: async (
    response: ApiResponse,
    config?: AxiosRequestConfig
  ): Promise<ApiResponse | null> => {
    const tokenRefreshed = await refreshTokenHandler.refreshToken();

    return tokenRefreshed && config
      ? axiosInstance.request(config)
      : response;
  },

  [ApiResponseAction.default]: async (
    response: ApiResponse
  ): Promise<ApiResponse | null> => {
    return response;
  },
};

const handleResponseByAction = async (
  response: ApiResponse,
  config?: AxiosRequestConfig
): Promise<ApiResponse | null> => {
  return response?.action
    && responseHandlersByAction[response.action]
    ? responseHandlersByAction[response.action](response, config)
    : response;
};

const handleResponse = async (
  response: ApiResponse,
  config?: AxiosRequestConfig
): Promise<ApiResponse | null> => {
  addNotificationsFromApiResponse(response);

  return handleResponseByAction(response, config);
};

axiosInstance.interceptors.request.use(
  config => {
    if (config.headers)
      config.headers.Lang = appLang.lang;

    return config;
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (!response.data) response.data = {};

    response.data.success = response.status >= 200
      && response.status < 300
      && !response.data.messages?.some(
        (message: ApiResponseMessage) =>
          message.type === ApiResponseMessageType.ERROR
      );

    return handleResponse(response.data, response.config);
  },
  async (error) => {
    if (axios.isCancel(error) || !error.response) return null;

    if (!error.response.data || typeof error.response.data !== 'object')
      error.response.data = {};

    error.response.data.success = false;

    return handleResponse(error.response.data, error.response.config);
  }
);

export default axiosInstance;
