import React, { FC, PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import DashboardHeader from './dashboard/header';
import { Box, BoxProps, Container, Stack, Typography, useTheme } from '@mui/material';
import Page from '../components/Page';
import ContactHelp from '../components/ContactHelp';
import { HEADER } from '../config';
import PageGrid from '../components/PageGrid';
import useTabResponsive from 'src/hooks/useTabResponsive';

interface AuthLayoutProps {
  pageTitle?: string
  heading?: string
  headingSx?: BoxProps['sx']
  showFlag?: boolean
}

const ContentStyle = styled('div')((/*{ theme }*/) => ({
  width: 480,
  maxWidth: '100%',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingTop: HEADER.MAIN_DESKTOP_HEIGHT,
}));

const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({
  pageTitle,
  heading,
  children,
  headingSx,
  showFlag,
}) => {
  const { palette } = useTheme();
  const isTab = useTabResponsive();

  return (
    <Page
      title={pageTitle}
      sx={{ backgroundColor: palette.common.white }}
    >
      <DashboardHeader verticalLayout />

      <PageGrid>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            py: 3,
            px: '0!important',
            minHeight: '100vh'
          }}
        >
          <ContentStyle>
            {
              showFlag
                ? (
                  <Stack alignItems="center" sx={{ mb: 4 }}>
                    <img
                      src="/logo/logo-footer.svg"
                      width={isTab ? 124 : 100}
                      alt=""
                    />
                  </Stack>
                )
                : undefined
            }

            {
              heading
                ? (
                  <Box
                    sx={{
                      mb: 5,
                      display: 'flex',
                      alignItems: 'center',
                      ...(headingSx || {})
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h4" align="center">
                        {heading}
                      </Typography>
                    </Box>
                  </Box>
                )
                : undefined
            }

            {children}
          </ContentStyle>

          <ContactHelp />
        </Container>
      </PageGrid>
    </Page>
  );
};

export default AuthLayout;