import create from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  AuthState,
  AuthStore,
  FaceIdResponseAction,
  FaceIdSocketListener,
  FaceIdSocketSource,
  LoginDTO,
  LoginWithQrPolling,
  RegisterState,
  ResetPasswordState,
} from './types';
import { AuthApi } from './auth.api';
import { useNotificationStore } from '../notification/notification.store';
import { ApiResponseMessageType } from '../../@types/api';
import { ReCaptchaAction } from '../../@types/re-captcha';

const subscribeToQrIdGenerate = (set: any, timer: any = {}) => {
  set({ qrLoading: true, qrId: '' });

  AuthApi.generateQrId()
    .then(qr => {
      set({ qrLoading: false, qrId: qr?.id || '' });

      if (qr?.expire_in) {
        timer.id = setTimeout(() =>
          subscribeToQrIdGenerate(set, timer), qr.expire_in * 1000) as any;
      }
    });

  return () => {
    set({ qrLoading: false, qrId: '' });

    timer.id && clearTimeout(timer.id);
  };
};

const SESSION_DEACTIVATE = 10;

const resetPasswordState: ResetPasswordState = {
  secretForResetPassword: '',
  loginForResetPassword: '',
  newPasswordForReset: '',
  phoneForResetPassword: '',
};

const registerState: RegisterState = {
  phoneForRegister: '',
  loginForRegister: '',
  passwordForRegister: '',
  secretForRegister: '',
};

const authState: AuthState = {
  isAuth: false,
  verified: true,
  isAdmin: false,
  meLoading: false,
  email: '',
  fullName: '',
  avatarUrl: '',
  verificationText: '',
  sessionDeactivate: SESSION_DEACTIVATE,
};

const useAuthStore = create(devtools<AuthStore>((set, get) => ({
  qrLoading: false,
  qrId: '',
  ...authState,
  ...resetPasswordState,
  ...registerState,

  resetAuthData: () => {
    set({ ...authState });
  },

  resetRegisterData: () => {
    set({ ...registerState });
  },

  resetPasswordData: () => {
    set({ ...resetPasswordState });
  },

  setVerified: (verified: boolean) => {
    set({ verified });
  },

  setIsAuth: (isAuth: boolean) => {
    set({ isAuth });
  },

  setLoginAndPasswordForRegister: (value: LoginDTO) => {
    set({
      loginForRegister: value.username,
      passwordForRegister: value.password
    });
  },

  register: async (): Promise<boolean> => {
    const response = await AuthApi.register({
      username: get().loginForRegister
    });

    set({
      secretForRegister: response?.secret || '',
      phoneForRegister: response?.phone || '',
    });

    return !!response?.secret;
  },

  verifyRegister: async (otp: string): Promise<boolean> => {
    const { loginForRegister, passwordForRegister, secretForRegister } = get();

    if (!secretForRegister) return false;

    const verified = await AuthApi.verifyRegister({
      username: loginForRegister,
      password: passwordForRegister,
      secret: secretForRegister,
      otp
    });

    verified && set({ isAuth: true });

    return verified;
  },

  setLoginAndPasswordForReset: (value: LoginDTO) => {
    set({
      loginForResetPassword: value.username,
      newPasswordForReset: value.password
    });
  },

  resetPassword: async (): Promise<boolean> => {
    const response = await AuthApi.resetPassword({
      username: get().loginForResetPassword
    });

    set({
      secretForResetPassword: response?.secret || '',
      phoneForResetPassword: response?.phone || '',
    });

    return !!response?.secret;
  },

  verifyResetPassword: async (otp: string): Promise<boolean> => {
    const { loginForResetPassword, newPasswordForReset, secretForResetPassword } = get();

    if (!secretForResetPassword) return false;

    return AuthApi.verifyResetPassword({
      username: loginForResetPassword,
      password: newPasswordForReset,
      secret: secretForResetPassword,
      otp
    });
  },

  subscribeToQrIdGenerate: () => subscribeToQrIdGenerate(set),

  activateQrPolling: async (id: string): Promise<boolean> =>
    AuthApi.activateQr(id),

  subscribeToQrPolling: (): (() => void) => {
    const { qrId } = get();
    let response: LoginWithQrPolling | undefined;

    if (qrId) {
      response = AuthApi.subscribeToQrActivation(qrId);

      response.request.then(async isAuth => {
        if (isAuth) {
          await get().loadMe();
          set({ isAuth });
        }

        return isAuth;
      });
    }

    return () => {
      response?.cancel();
    };
  },

  loadMe: async () => {
    set({ meLoading: true });

    const response = await AuthApi.loadMe();

    set({
      email: response?.email || '',
      fullName: response?.full_name || '',
      avatarUrl: response?.cover_url || '',
      meLoading: false,
      sessionDeactivate: response?.session_deactivate || SESSION_DEACTIVATE,
    });
  },

  login: async (loginDto: LoginDTO) => {
    const isAuth = await AuthApi.login(loginDto);

    isAuth && get().verified && await get().loadMe();

    set({ isAuth });
  },

  logout: async () => {
    get().resetAuthData();

    await AuthApi.logout();
  },

  subscribeToFaceId: async (
    dataGetter: () => Promise<FaceIdSocketSource>,
    generateReCaptcha: (action?: string) => Promise<string>
  ): Promise<FaceIdSocketListener | null> => {
    const captcha = await generateReCaptcha(ReCaptchaAction.faceIdStream);

    if (!captcha) return null;

    return AuthApi.subscribeToFaceId(
      dataGetter,
      captcha,
      async response => {
        const hasPlaceholderMessage = (
          response?.message?.type === ApiResponseMessageType.PLACEHOLDER
        );

        set({
          verificationText: (
            hasPlaceholderMessage && response?.message?.text
          ) || ''
        });

        if (response?.message?.text && !hasPlaceholderMessage) {
          useNotificationStore.getState().addNotification({
            message: response.message.text,
            type: response.message.type
          });
        }

        if (response?.action === FaceIdResponseAction.verified) {
          const captchaForVerifyFaceId = await generateReCaptcha(
            ReCaptchaAction.faceIdVerify
          );

          if (!captchaForVerifyFaceId) return;

          await AuthApi.verifyFaceIdToken(
            { token: response?.result?.token || '' },
            captchaForVerifyFaceId
          );

          get().loadMe();
          set({ verified: true, verificationText: '' });
        }
      }
    );
  },
})));

export default useAuthStore;