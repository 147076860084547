import { LocaleField, LocaleFields, LocaleFieldsByLang, LocaleFieldUpdate, LocaleLang } from './types';
import api from '../../utils/api';
import { ApiResponse } from '../../@types/api';

export class LocaleCrudApi {

  static async addLocaleField(
    field: LocaleFieldUpdate
  ): Promise<LocaleFieldUpdate | null> {
    const response = await api.post('admin/locale/field', field) as ApiResponse;

    return response?.success ? field : null;
  }

  static async updateLocaleField(
    field: LocaleFieldUpdate
  ): Promise<LocaleFieldUpdate | null> {
    const response = await api.patch('admin/locale/field', field) as ApiResponse;

    return response?.success ? field : null;
  }

  static async addLangToApp(
    field: Partial<LocaleLang>
  ): Promise<Partial<LocaleLang> | null> {
    const response = await api.post('admin/locale/lang', field) as ApiResponse;

    return response?.success ? field : null;
  }

  static async removeLangFromApp(
    field: Partial<LocaleLang>
  ): Promise<Partial<LocaleLang> | null> {
    const response = await api.delete('admin/locale/lang' + field.lang) as ApiResponse;

    return response?.success ? field : null;
  }

  static async getLocaleFields(
    isAdmin: boolean = false
  ): Promise<LocaleField | null> {
    const response = await api.get(
      (isAdmin ? 'admin/' : '') +
      'locale/field'
    ) as ApiResponse;

    return response?.data;
  }

  static async getLocaleFieldsByLang(
    field: Partial<LocaleLang>,
    isAdmin: boolean = false
  ): Promise<LocaleFieldsByLang | null> {
    const response = await api.get(
      (isAdmin ? 'admin/' : '') +
      'locale/field/' +
      field.lang
    ) as ApiResponse;

    return response?.data;
  }

  static async getAppLanguages(
    isAdmin: boolean = false
  ): Promise<LocaleFields | null> {
    const response = await api.get(
      (isAdmin ? 'admin/' : '') +
      'locale/lang'
    ) as ApiResponse;

    return response?.data;
  }

  static async getAvailableLanguages(
    isAdmin: boolean = false
  ): Promise<LocaleFields | null> {
    const response = await api.get(
      (isAdmin ? 'admin/' : '') +
      'locale/lang/all'
    ) as ApiResponse;

    return response?.data;
  }

}