import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { SearchStore } from './types';
import { SearchApi } from './search.api';
import { ConditionalExcept } from 'type-fest';
import { BookSelection } from '../books-crud/types';

const PAGE_SIZE = 30;

const getInitialState = (): ConditionalExcept<SearchStore, Function> => ({
  list: [],
  loading: true,
  page: 1,
  pagesCount: 0,
  booksCount: 0,
  selectionsCount: 0,
  query: '',
  searchText: '',
});

export const useSearchStore = create(devtools<SearchStore>((set, get) => ({
  ...getInitialState(),

  reset: () => {
    set(getInitialState());
  },

  setList: (list: BookSelection[]) => {
    set({ list });
  },

  setQuery: (query: SearchStore['query']) => {
    set({ query });
  },

  setPage: (page: number) => {
    set({ page });
  },

  setSearchText: (searchText: string) => {
    set({ searchText });
  },

  load: async () => {
    set({ loading: true });

    const { query, page } = get();

    const response = await SearchApi.load(
      query, { page, size: PAGE_SIZE }
    );

    set({
      loading: false,
      list: response?.result || [],
      pagesCount: response?.pages || 0,
      booksCount: response?.books || 0,
      selectionsCount: response?.selections || 0,
    });
  }
})));