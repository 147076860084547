import React, { FC } from 'react';
import { Box, BoxProps } from '@mui/material';
import { UserStatement } from '../../store/user-statement/types';
import InvoiceShortListItem from './InvoiceShortListItem';

interface InvoiceShortListProps extends Pick<BoxProps, 'sx'> {
  list?: UserStatement[]
}

const InvoiceShortList: FC<InvoiceShortListProps> = ({
  list,
  sx,
}) => {
  return (
    <Box sx={sx}>
      {
        list?.map((item, index) => (
          <InvoiceShortListItem
            key={index}
            sx={{ mt: index ? 1 : 0 }}
            {...item}
          />
        ))
      }
    </Box>
  );
};

export default InvoiceShortList;