import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
import { PATH_PAGE, STATIC_LINKS } from '../../routes/paths';
import SocialsButton from '../../components/SocialsButton';
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink } from 'react-router-dom';
import useLocales from '../../hooks/useLocales';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 3,
  backgroundColor: theme.palette.background.default,
}));

const currentYear = new Date().getFullYear();

export default function MainFooter() {
  const { gridWidthStyle } = useSettings();
  const { currentLang, translate } = useLocales();

  const LINKS = [
    {
      headline: translate('documents'),
      children: [
        {
          name: translate('privacyPolicy'),
          href: STATIC_LINKS.privacyPolicy(currentLang.lang),
          target: '_blank'
        },
      ],
    },
    {
      headline: translate('administration'),
      children: [
        {
          name: translate('phoneNumber'),
          href: `tel:${translate('phoneNumber')}`
        },
        {
          name: translate('createApplication'),
          path: PATH_PAGE.userInvoices
        }
      ],
    },
  ];

  return (
    <RootStyle>
      <Divider />

      <Container sx={{ pt: 6, ...gridWidthStyle }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid
            item
            xs={12}
            sx={{
              mb: 3,
              img: {
                mx: { md: '0', xs: 'auto' }
              }
            }}
          >
            <img
              src="/logo/logo-footer.svg"
              width="64"
              alt=""
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {translate('yourOnlineLibrary')}
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list, index) => (
                <Stack key={index} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link: any) => (
                    <Link
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      sx={{ display: 'block' }}
                      {...(link.path ? {
                        component: RouterLink,
                        to: link.path,
                      } : {
                        target: link.target,
                        href: link.href
                      })}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © {currentYear}. {translate('allRightsReserved')}
        </Typography>
      </Container>
    </RootStyle>
  );
}
