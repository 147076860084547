import React, { FC } from 'react';
import InvoiceList from './InvoiceList';
import DialogContainer from '../dialog/DialogContainer';
import { DialogProps, useTheme } from '@mui/material';
import { UserStatement } from '../../store/user-statement/types';
import ListPagination from '../pagination/ListPagination';
import useLocales from '../../hooks/useLocales';

interface InvoiceListDialogProps extends Pick<DialogProps, 'sx'> {
  list?: UserStatement[]
  page?: number
  pagesCount?: number
  loading?: boolean
  onPageChange?: (page: number) => void
  open: boolean
  setOpen?: (open: boolean) => void
}

const InvoiceListDialog: FC<InvoiceListDialogProps> = ({
  sx,
  list,
  page,
  pagesCount,
  loading,
  onPageChange,
  open,
  setOpen,
}) => {
  const { palette } = useTheme();
  const onClose = () => setOpen && setOpen(false);
  const { translate } = useLocales();

  return (
    <DialogContainer
      open={open}
      contentSx={{
        width: 744,
        maxWidth: '100%',
        p: 2,
        ...(sx || {})
      }}
      title={translate('listApplications')}
      closeable
      onClose={onClose}
    >
      <InvoiceList
        list={list}
        onItemClick={onClose}
      />

      <ListPagination
        page={page}
        pages={pagesCount}
        setPage={onPageChange}
        loading={loading}
        loadingSx={{ pt: list?.length ? 2 : 0 }}
        bg_color={palette.grey[500_16]}
        sx={{ mt: 2 }}
      />
    </DialogContainer>
  );
};

export default InvoiceListDialog;