import useAuthStore from '../store/auth/auth.store';
import { Outlet } from 'react-router-dom';
import FaceId from '../pages/auth/FaceId';

const FaceIdGuard = () => {
  const {
    verified
  } = useAuthStore(state => ({
    verified: state.verified
  }));

  return verified ? <Outlet /> : <FaceId />;
};

export default FaceIdGuard;