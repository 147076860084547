import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { FC, PropsWithChildren } from 'react';

const reCaptchaKey: string = process.env.REACT_APP_RE_CAPTCHA_KEY!;

const ReCaptchaProvider: FC<PropsWithChildren> = ({
  children
}) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={reCaptchaKey}
      useEnterprise
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default ReCaptchaProvider;