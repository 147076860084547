import { LoginForm } from '../../sections/auth/login';
import AuthLayout from '../../layouts/AuthLayout';
import useLocales from '../../hooks/useLocales';

export default function Login() {
  const { translate } = useLocales();

  return (
    <AuthLayout
      pageTitle={translate('loginTo')}
      heading={translate('signInLibrary')}
      showFlag
    >
      <LoginForm />
    </AuthLayout>
  );
}
