import { Box, Button, Card, CardProps, Stack, Typography } from '@mui/material';
import { UserStatement } from '../../store/user-statement/types';
import { FC } from 'react';
import Label from '../Label';
import { getPathByPageType } from '../../routes/paths';
import { BookSelectionType } from '../../store/books-crud/types';
import { Link as RouterLink } from 'react-router-dom';
import useLocales from '../../hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import InvoiceListItemId from './InvoiceListItemId';

interface InvoiceListItemProps extends Pick<CardProps, 'sx'>, UserStatement {
  onClick?: () => void
}

const InvoiceListItem: FC<InvoiceListItemProps> = ({
  sx,
  status,
  type,
  id,
  body,
  onClick,
}) => {
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'sm');

  return (
    <Card
      sx={{
        p: 3,
        ...(sx || {})
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
      >
        <Box
          sx={{
            mr: isMobile ? 0 : 1.5,
            overflow: 'hidden',
            flexGrow: 1,
            width: isMobile ? '100%' : 'auto'
          }}
        >
          <Stack
            direction={isMobile ? 'column-reverse' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
          >
            <Typography
              variant="subtitle1"
              sx={{
                mr: isMobile ? 0 : 1,
                mt: isMobile ? 1 : 0
              }}
            >
              {type?.title}
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: isMobile ? '100%' : 'auto' }}
            >
              {
                status && (
                  <Label
                    sx={{
                      color: status?.color,
                      backgroundColor: status?.bg_color,
                      mr: isMobile ? 1.5 : 0
                    }}
                  >
                    {status?.title}
                  </Label>
                )
              }

              {
                isMobile
                  ? <InvoiceListItemId id={id} />
                  : undefined
              }
            </Stack>
          </Stack>

          <Typography variant="body2" noWrap sx={{ mt: 1 }}>
            {body}
          </Typography>

          {
            isMobile
              ? undefined
              : <InvoiceListItemId id={id} sx={{ mt: 1 }} />
          }
        </Box>

        <Stack
          sx={{ mt: isMobile ? 1 : 0 }}
          justifyContent="flex-end"
        >
          <Button
            variant="outlined"
            size="small"
            component={RouterLink}
            to={getPathByPageType({ id, type: BookSelectionType.invoice_details })}
            onClick={onClick}
          >
            {translate('view')}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default InvoiceListItem;