import { useState } from 'react';
import { CircularProgress, MenuItem, Stack } from '@mui/material';
import useLocales from '../../../hooks/useLocales';
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { useLocaleCRUD } from '../../../store/locale-crud/locale-crud.store';

export default function LanguagePopover() {
  const { allLangs, currentLang, onChangeLang } = useLocales();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const {
    iconsBaseUrl,
    localeByLangLoading,
  } = useLocaleCRUD(state => ({
    iconsBaseUrl: state.iconsBaseUrl,
    localeByLangLoading: state.localeByLangLoading,
  }));

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang: string) => {
    onChangeLang(newLang);
    handleClose();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        {
          localeByLangLoading
            ? (
              <Stack>
                <CircularProgress size={20} />
              </Stack>
            )
            : (
              <Image
                disabledEffect
                src={iconsBaseUrl + currentLang?.icon}
                alt=""
                radius="3px"
              />
            )
        }
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.lang}
              selected={option.lang === currentLang?.lang}
              onClick={() => handleChangeLang(option.lang)}
            >
              <Image
                disabledEffect
                alt=""
                src={iconsBaseUrl + option.icon}
                sx={{ width: 28, mr: 2 }}
                radius="3px"
              />

              {option.value}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
