import { useContext } from 'react';
import { SettingsContext } from '../contexts/SettingsContext';
import useResponsive from './useResponsive';

const transition = 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';

const useSettings = () => {
  const settings = useContext(SettingsContext);
  const gridWidth = settings.themeStretch ? '100%' : 1200;
  const isTab = useResponsive('up', 'sm');

  return {
    ...settings,
    gridWidth,
    gridWidthStyle: {
      width: gridWidth,
      maxWidth: '100%',
      px: isTab ? 3 : 2,
      transition
    },
    gridWidthTransition: transition
  };
};

export default useSettings;
