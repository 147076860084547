import { SearchResponse, SearchStore } from './types';
import api from '../../utils/api';
import { ApiResponse } from '../../@types/api';
import { PaginationApiRequest } from '../../@types/pagination';

export class SearchApi {

  static async load(
    query: SearchStore['query'],
    pagination: PaginationApiRequest
  ): Promise<SearchResponse | null> {
    const response = await api.get('search?' + query, {
      params: pagination
    }) as ApiResponse;

    return response?.data || null;
  }

}