import createAvatar from '../utils/createAvatar';
import Avatar, { Props as AvatarProps } from './Avatar';
import useAuthStore from '../store/auth/auth.store';

export default function MyAvatar({ ...other }: AvatarProps) {
  const {
    avatarUrl,
    fullName,
  } = useAuthStore(state => ({
    avatarUrl: state.avatarUrl,
    fullName: state.fullName,
  }));

  return (
    <Avatar
      src={avatarUrl}
      alt={fullName}
      color={avatarUrl ? 'default' : createAvatar(fullName).color}
      {...other}
    >
      {createAvatar(fullName).name}
    </Avatar>
  );
}
