import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { FirebaseUtils } from '../@types/firebase';
import { FirebaseOptions } from '@firebase/app';

export const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const firebaseUtils: FirebaseUtils = {
  app: initializeApp(firebaseConfig),
  analytics: null,
  messaging: null,
  messagingToken: ''
};

firebaseUtils.analytics = getAnalytics(firebaseUtils.app);

(async () => {
  try {
    if (await isSupported()) {
      firebaseUtils.messaging = getMessaging(firebaseUtils.app);
      firebaseUtils.messagingToken = await getToken(firebaseUtils.messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY
      });

      onMessage(firebaseUtils.messaging, (payload) => {
        new Notification(
          payload.data?.title || '',
          Object.assign(payload.data || {}, {
            icon: payload.data?.icon || '/favicon/ms-icon-310x310.png',
            onClick: () => window.open(
              process.env.NODE_ENV === 'production'
                ? process.env.REACT_APP_PROD_HOST
                : process.env.REACT_APP_HOST
            )
          })
        );
      });
    }
  } catch (error) {
    console.log('Firebase Messaging Error', error);
  }
})();
