import { useState, MouseEvent } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useAuthStore from '../../../store/auth/auth.store';
import useLocales from '../../../hooks/useLocales';
import Cookies from 'js-cookie';

const ADMIN_MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   linkTo: '/',
  // },
  // {
  //   label: 'Profile',
  //   linkTo: PATH_DASHBOARD.user.profile,
  // },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

export default function AccountPopover() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const [ open, setOpen ] = useState<HTMLElement | null>(null);

  const CLIENT_MENU_OPTIONS = [
    {
      label: translate('profile'),
      linkTo: PATH_PAGE.userProfile,
    },
    {
      label: translate('myApplications'),
      linkTo: PATH_PAGE.userInvoices,
    }
  ];

  const {
    isAdmin,
    email,
    fullName,
    logout,
  } = useAuthStore(state => ({
    isAdmin: state.isAdmin,
    email: state.email,
    fullName: state.fullName,
    logout: state.logout,
  }));

  const MENU_OPTIONS = isAdmin ? ADMIN_MENU_OPTIONS : CLIENT_MENU_OPTIONS;

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onLogout = () => {
    logout();
    Cookies.remove('is_auth');
    navigate(PATH_AUTH.login);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {fullName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={onLogout} sx={{ m: 1 }}>
          {translate('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
