import { memo, PropsWithChildren } from 'react';
import { Stack, Divider } from '@mui/material';
import { NavSectionProps } from '../type';
import NavList from './NavList';

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

function NavSectionHorizontal({ navConfig, children }: PropsWithChildren<NavSectionProps>) {
  return (
    <Stack
      direction="row"
      sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}
    >
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {navConfig.map((group, index) => (
          <Stack
            key={index}
            direction="row"
            flexShrink={0}
            alignItems="center"
          >
            {group.items.map((list, index) => (
              <NavList
                key={list.title + list.path + index}
                data={list}
                depth={1}
                hasChildren={!!list.children}
              />
            ))}

            {
              children
                ? (
                  <Divider
                    orientation="vertical"
                    sx={{
                      mx: .5,
                      height: 20
                    }}
                  />
                )
                : undefined
            }

            {children}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
