import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import useTabResponsive from 'src/hooks/useTabResponsive';
import useLocales from '../../../hooks/useLocales';

interface FaceIdPrepareProps {
  onContinue?: () => void
}

const FaceIdPrepare: FC<FaceIdPrepareProps> = ({
  onContinue
}) => {
  const { translate } = useLocales();
  const isTab = useTabResponsive();

  const handleContinue = () => {
    onContinue && onContinue();
  };

  return (
    <Stack alignItems="center">
      <img
        src="/assets/illustrations/illustration_booking.svg"
        width={isTab ? 272 : 150}
        alt=""
      />

      <Typography
        variant="h4"
        sx={{ mt: 1, textAlign: 'center', px: 2 }}
      >
        {translate('makeSureStudent')}
      </Typography>

      <Typography
        color="text.secondary"
        sx={{ mt: 1 }}
        align={isTab ? 'left' : 'center'}
      >
        {translate('checkConditions')}
      </Typography>

      <Button
        fullWidth
        variant="contained"
        size="large"
        sx={{ mt: isTab ? 5 : 3 }}
        onClick={handleContinue}
      >
        {translate('continue')}
      </Button>
    </Stack>
  );
};

export default FaceIdPrepare;