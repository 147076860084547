import { PaginationApiRequest, PaginationApiResponse } from '../../@types/pagination';
import {
  UserStatement,
  UserStatementCreated,
  UserStatementPreInfo,
  UserStatementToCreate,
} from './types';
import api from '../../utils/api';
import { ApiResponse } from '../../@types/api';
import { FileAttached } from '../../components/upload';

export class UserStatementApi {

  static async create(
    body: UserStatementToCreate
  ): Promise<UserStatementCreated | null> {
    const response = await api.post('user/application', body) as ApiResponse;

    return response?.data?.id
      ? { ...body, ...response.data }
      : null;
  }

  static async attachFiles(
    id: string,
    body: File
  ): Promise<FileAttached | null> {
    const formData = new FormData();

    formData.append('file', body);

    const response = await api.post(
      `user/application/${id}/attach`,
      formData
    );

    return response?.data?.url
      ? {
        title: body.name,
        url: response.data.url,
        size: body.size / 1024
      }
      : null;
  }

  static async getList(
    params: PaginationApiRequest
  ): Promise<PaginationApiResponse<UserStatement> | null> {
    const response = await api.get(
      'user/application/list',
      { params }
    ) as ApiResponse;

    return response?.data || null;
  }

  static async getById(
    id: string
  ): Promise<UserStatement | null> {
    const response = await api.get('user/application/' + id) as ApiResponse;

    return response?.data || null;
  }

  static async getPreInfo(): Promise<UserStatementPreInfo | null> {
    const response = await api.get('user/application/info') as ApiResponse;

    return response?.data || null;
  }

}