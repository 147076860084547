import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { defaultLang } from '../config';

export const getDefaultLang = () => localStorage.getItem('i18nextLng')
  || navigator.language?.slice(0, 2)
  || defaultLang;

export const appLang = {
  lang: getDefaultLang()
};


export const langNamespace = 'translations';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: appLang.lang,
    fallbackLng: defaultLang,
    debug: false,
    ns: [langNamespace],
    defaultNS: langNamespace,
    interpolation: {
      escapeValue: false,
    },
  });

export const setAppLang = (lang: string) => {
  i18n.changeLanguage(lang);

  appLang.lang = lang;
};

export const addLocalesToApp = (lang: string, resource: any) => {
  i18n
    .removeResourceBundle(lang, langNamespace)
    .addResources(lang, langNamespace, resource);
};

export default i18n;
