import React, { FC } from 'react';
import { Stack, StackProps, Typography } from '@mui/material';
import useLocales from '../../hooks/useLocales';

interface EmptyPlaceholderProps extends Pick<StackProps, 'sx'> {}

const EmptyPlaceholder: FC<EmptyPlaceholderProps> = ({
  sx
}) => {
  const { translate } = useLocales();

  return (
    <Stack
      alignItems="center"
      sx={{
        textAlign: 'center',
        pt: 5,
        px: 3,
        pb: 8,
        borderRadius: '8px',
        backgroundColor: theme => theme.palette.grey[200],
        border: theme => `1px dashed ${theme.palette.grey[500_32]}`,
        ...(sx || {}),
      }}
    >
      <img src='/assets/illustrations/illustration_upload.svg' alt='' />

      <Typography variant="h5" sx={{ mt: 3 }}>
        {translate('listEmpty')}
      </Typography>

      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
        {translate('ifHaveProblem')}
      </Typography>
    </Stack>
  );
};

export default EmptyPlaceholder;