import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { NotificationAddable, NotificationStore } from './types';
import { getUniqueAppNumber } from '../../utils/randomNumber';

export const useNotificationStore = create(devtools<NotificationStore>((set, get) => ({
  notificationList: [],

  addNotification: (payload: NotificationAddable): number => {
    const id = getUniqueAppNumber();

    set({
      notificationList: [
        ...get().notificationList,
        { ...payload, id }
      ]
    });

    return id;
  },

  deleteNotification: (id: number) => {
    set({
      notificationList: get().notificationList.filter(
        item => item.id !== id
      )
    });
  }
})));