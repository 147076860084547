import { List, IconButton, ListItemText, ListItem, Stack, CircularProgress } from '@mui/material';
import { fData } from '../../utils/formatNumber';
import getFileData from '../../utils/getFileData';
import { UploadMultiFileProps } from './type';
import Iconify from '../Iconify';
import useLocales from '../../hooks/useLocales';

export default function MultiFilePreview({
  files,
  onRemove,
  sx,
}: UploadMultiFileProps) {
  const { translate } = useLocales();

  return (
    <List disablePadding sx={sx}>
      {files.map((file, index) => {
        const { title, size, url, loading } = getFileData(file);

        return (
          <ListItem
            key={index}
            sx={{
              mt: 2,
              px: 2,
              py: 1.75,
              borderRadius: 1,
              backgroundColor: theme => theme.palette.grey[200]
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                position: 'relative',
                borderRadius: '50%',
                backgroundColor: theme => theme.palette.primary.main,
                width: 40,
                minWidth: 40,
                height: 40,
                mr: 2
              }}
            >
              <Iconify
                icon="eva:file-fill"
                color="common.white"
                fontSize={24}
                sx={{
                  position: 'relative',
                  zIndex: 2
                }}
              />

              {
                loading
                  ? (
                    <CircularProgress
                      size={36}
                      thickness={2}
                      sx={{
                        position: 'absolute',
                        zIndex: 1,
                        left: 2,
                        top: 2,
                        color: '#FFFFFF'
                      }}
                    />
                  )
                  : undefined
              }
            </Stack>

            <ListItemText
              primary={typeof file === 'string' ? file : title}
              secondary={typeof file === 'string' ? '' : `${fData(size || 0)} ${translate('megaByte')}`}
              primaryTypographyProps={{ variant: 'subtitle2' }}
              secondaryTypographyProps={{ variant: 'body2', color: 'text.disabled' }}
              sx={{ mr: 2, cursor: url ? 'pointer' : 'text' }}
              onClick={() => url && window.open(url)}
            />

            {onRemove && (
              <IconButton
                sx={{ width: 36, height: 36 }}
                onClick={() => onRemove(file)}
              >
                <Iconify icon="eva:trash-2-outline" fontSize={20} />
              </IconButton>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}
