import RouterWithLoading from './routes';
import ThemeProvider from './theme';
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotificationFromStore from './components/NotificationFromStore';
import { useAuthInit } from './hooks/useAuthInit';
import { useLocaleInit } from './hooks/useLocaleInit';

export default function App() {
  useAuthInit();
  useLocaleInit();

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ScrollToTop />
            <RouterWithLoading />
            <NotificationFromStore />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
