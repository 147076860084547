import React, { FC } from 'react';
import { Box, Card, CardProps, Stack, Typography, useTheme } from '@mui/material';
import { UserStatement } from '../../store/user-statement/types';
import Iconify from '../Iconify';
import Label from '../Label';
import { Link as RouterLink } from 'react-router-dom';
import { getPathByPageType } from '../../routes/paths';
import { BookSelectionType } from '../../store/books-crud/types';

interface InvoiceShortListItemProps extends Pick<CardProps, 'sx'>, UserStatement {}

const InvoiceShortListItem: FC<InvoiceShortListItemProps> = ({
  sx,
  type,
  id,
  status,
}) => {
  const { palette } = useTheme();
  const onClickInvoice = () => window.scrollTo(0, 0);

  return (
    <Card
      sx={{
        px: 2,
        pt: 1,
        pb: 1.5,
        borderRadius: '8px',
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'block',
        ...(sx || {})
      }}
      component={RouterLink}
      to={getPathByPageType({ id, type: BookSelectionType.invoice_details })}
      onClick={onClickInvoice}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box sx={{ mr: 1.5 }}>
          {
            type?.title
              ? (
                <Typography variant="body2" noWrap>
                  {type?.title}
                </Typography>
              )
              : undefined
          }

          <Typography variant="body2" color="text.secondary" noWrap>
            № {id}
          </Typography>

          {
            status
              ? (
                <Label
                  sx={{
                    color: status?.color,
                    backgroundColor: status?.bg_color,
                    mt: 1
                  }}
                >
                  {status?.title}
                </Label>
              )
              : undefined
          }
        </Box>

        <Iconify
          fontSize={20}
          color={palette.grey[500]}
          icon="ic:round-chevron-right"
        />
      </Stack>
    </Card>
  );
};

export default InvoiceShortListItem;