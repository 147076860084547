import { FileAttached } from '../components/upload';

export default function getFileData(file: FileAttached | File | string) {
  if (typeof file === 'string') {
    return { url: file };
  }

  const attachedFile = file as FileAttached;

  if (attachedFile.title && attachedFile.url) {
    return {
      ...attachedFile,
      size: (attachedFile.size || 0) / 1024
    };
  }

  const fileItem = file as File;

  return {
    title: fileItem.name,
    size: fileItem.size / 1024 / 1024,
    url: (fileItem as any).preview,
    loading: attachedFile.loading
  };
}
