import { FC, forwardRef, PropsWithChildren, ReactElement, Ref } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
} from '@mui/material';
import Iconify from '../Iconify';

interface DialogContainerProps extends Pick<DialogProps, 'sx'> {
  closeable?: boolean
  onClose?: () => void
  open: boolean
  title?: string
  contentSx?: DialogProps['sx']
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction='up' ref={ ref } { ...props } />;
});

const DialogContainer: FC<PropsWithChildren<DialogContainerProps>> = ({
  closeable,
  children,
  open,
  onClose,
  sx,
  title,
  contentSx,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      hideBackdrop
      scroll="paper"
      sx={{
        backgroundColor: theme => theme.palette.grey[900_48],
        ...(sx || {})
      }}
    >
      {
        (closeable || title)
          ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={title ? 'space-between' : 'flex-end'}
              sx={{ pt: 3, pb: 1, pl: 3, pr: 1.5 }}
            >
              {
                title && (
                  <DialogTitle sx={{ p: 0, mr: 1.5 }}>{title}</DialogTitle>
                )
              }

              {
                closeable
                  ? (
                    <IconButton onClick={onClose}>
                      <Iconify icon="ic:round-close" />
                    </IconButton>
                  )
                  : undefined
              }
            </Stack>
          )
          : undefined
      }

      {
        children && (
          <DialogContent sx={contentSx}>
            {children}
          </DialogContent>
        )
      }
    </Dialog>
  );
};

export default DialogContainer;