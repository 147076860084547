import React, { FC } from 'react';
import { Box, BoxProps } from '@mui/material';
import useSettings from '../hooks/useSettings';
import { usePageGrid } from '../hooks/usePageGrid';

interface PageGridProps extends BoxProps {}

const PageGrid: FC<PageGridProps> = ({
  sx,
  children,
  ...otherProps
}) => {
  const { gridWidthStyle } = useSettings();
  const { disable } = usePageGrid();

  return (
    <Box
      sx={{
        mx: 'auto',
        position: 'relative',
        ...(disable ? {} : gridWidthStyle),
        ...(sx || {})
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

export default PageGrid;