import { useEffect, useRef } from 'react';

export const useUpdate = (
  callback: () => any,
  dependencies: any[]
) => {
  const ref = useRef(false);

  useEffect(
    () => {
      if (ref.current) callback();

      ref.current = true;
    },
    dependencies
  );
};