import { Box, Typography, Stack } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import useLocales from '../../hooks/useLocales';

export default function BlockContent() {
  const { translate } = useLocales();
  const isLaptop = useResponsive('down', 'md');

  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <img
        src="/assets/illustrations/illustration_upload.svg"
        alt=""
        width={isLaptop ? 135 : 109}
      />

      <Box sx={{ ml: { md: '24px!important' } }}>
        <Typography gutterBottom variant="h5">
          {translate('selectFiles')}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {translate('placeFiles')}

          {/*TODO view select <Typography*/}
          {/*  variant="body2"*/}
          {/*  component="span"*/}
          {/*  sx={{ color: 'primary.main', textDecoration: 'underline' }}*/}
          {/*/>*/}
        </Typography>
      </Box>
    </Stack>
  );
}
