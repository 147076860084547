// import { enUS, frFR, zhCN, arSD, ruRU, esES } from '@mui/material/locale';
import { SettingsValueProps } from './components/settings/type';
import { PATH_DASHBOARD } from './routes/paths';

export const PROD_API_URL = 'https://mock.kebzu.com/api/v1/';
export const PROD_WS_URL = 'wss://mock.kebzu.com/api/v1/';
export const DEV_API_URL = 'http://localhost:8004/api/v1/';
export const DEV_WS_URL = 'ws://localhost:8004/api/v1/';
// export const DEV_API_URL = 'https://057e-195-158-20-158.eu.ngrok.io/api/v1/';
// export const DEV_WS_URL = 'wss://057e-195-158-20-158.eu.ngrok.io/api/v1/';

export const API_URL = (
  process.env.NODE_ENV === 'production' ? PROD_API_URL : DEV_API_URL
) || '';

export const WS_API = (
  process.env.NODE_ENV === 'production' ? PROD_WS_URL : DEV_WS_URL
) || '';

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 80,
  DASHBOARD_DESKTOP_HEIGHT: 80,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 80,
  NAV_HEIGHT: 68
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const DETAIL_PAGE_ASIDE_WIDTH = 270;

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

export const defaultLang = 'uz';
