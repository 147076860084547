import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useCallback } from 'react';

export const useReCaptcha = (actionFromHook?: string) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  return useCallback(
    async (action?: string) => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');

        return '';
      }

      const captcha = await executeRecaptcha(action || actionFromHook);

      if (!captcha) {
        console.log('Failed recaptcha not yet available');

        return '';
      }

      return captcha;
    },
    [executeRecaptcha]
  );
};