import { Button, Card, CircularProgress, Stack, Typography, Link } from '@mui/material';
import Page from '../../components/Page';
import PageGrid from '../../components/PageGrid';
import { Outlet } from 'react-router';
import { useEffect, useState } from 'react';
import Iconify from '../../components/Iconify';
import EmptyPlaceholder from '../../components/placeholder/EmptyPlaceholder';
import { useUserStatementStore } from '../../store/user-statement/user-statement.store';
import InvoiceShortList from '../../components/invoice/InvoiceShortList';
import InvoiceListDialog from '../../components/invoice/InvoiceListDialog';
import { useUpdate } from '../../hooks/useUpdate';
import useAuthStore from '../../store/auth/auth.store';
import MainLayout from '../../layouts/main';
import useLocales from '../../hooks/useLocales';
import { useLangChange } from 'src/hooks/useLangChange';
import useResponsive from 'src/hooks/useResponsive';

const InvoiceLayout = () => {
  const [ title, setTitle ] = useState<string>('');
  const [ invoiceDialogOpen, setInvoiceDialogOpen ] = useState<boolean>(false);
  const isGuest = useAuthStore(state => !state.isAuth || !state.verified);
  const { translate } = useLocales();
  const isLaptop = useResponsive('down', 'md');
  const isCustomTab = useResponsive('down', 740);
  const isMobile = useResponsive('down', 'sm');

  const {
    partialList,
    loadStatementPreInfo,
    loadStatementList,
    statementListLoading,
    statementPreInfoLoading,
    contact,
    list,
    page,
    pagesCount,
    setPage,
    loadStatementPartialList,
    statementPartialListLoading,
    reset,
    setList,
    setPagesCount,
  } = useUserStatementStore(state => ({
    partialList: state.partialList,
    loadStatementPreInfo: state.loadStatementPreInfo,
    loadStatementList: state.loadStatementList,
    loadStatementPartialList: state.loadStatementPartialList,
    statementListLoading: state.statementListLoading,
    statementPartialListLoading: state.statementPartialListLoading,
    statementPreInfoLoading: state.statementPreInfoLoading,
    contact: state.contact,
    list: state.list,
    page: state.page,
    pagesCount: state.pagesCount,
    setPage: state.setPage,
    reset: state.reset,
    setList: state.setList,
    setPagesCount: state.setPagesCount,
  }));

  const onPageChange = (page: number) => {
    setPage(page);
    loadStatementList();
  };

  const onInvoicesDialogOpen = () => {
    setList([]);
    setPagesCount(0);
    setPage(1);
    loadStatementList();
  };

  const onClickShowAllInvoices = () => setInvoiceDialogOpen(true);

  const loadStatementSettings = () => {
    loadStatementPreInfo();
    isGuest || loadStatementPartialList();
  };

  useEffect(() => {
    loadStatementSettings();

    return () => {
      reset();
    };
  }, []);

  useUpdate(() => {
    invoiceDialogOpen && onInvoicesDialogOpen();
  }, [invoiceDialogOpen]);

  useLangChange(loadStatementSettings);

  return (
    <MainLayout hideNavbar={isGuest}>
      <Page
        title={title}
        sx={{
          backgroundColor: 'none',
          pb: 4,
          pt: isGuest ? (isMobile ? 8 : 10) : 0
        }}
      >
        <PageGrid>
          <Typography
            sx={{
              pt: isGuest ? 4 : 3,
              pb: 4.5
            }}
            variant="h4"
          >
            {title}
          </Typography>

          <Stack
            direction="row"
            alignItems="flex-start"
            flexWrap={isCustomTab ? 'wrap' : 'nowrap'}
          >
            <Card
              sx={{
                p: isMobile ? 2 : 3,
                mr: isCustomTab ? 0 : 3,
                flexGrow: 1,
                mb: isCustomTab ? 5 : 0
              }}
            >
              <Outlet context={{ setTitle, isGuest }} />
            </Card>

            <Card
              sx={{
                borderRadius: '16px',
                p: 3,
                width: isCustomTab
                  ? '100%'
                  : (isLaptop ? 300 : 344),
                flex: 'none',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
              >
                <Iconify
                  icon="ic:outline-info"
                  fontSize={24}
                  sx={{ mr: 2 }}
                />

                <Typography variant="h6">
                  {translate('information')}
                </Typography>
              </Stack>

              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: 2 }}
              >
                {translate('phoneInfo')}
              </Typography>

              <Typography
                variant="h5"
                color="primary"
                sx={{ mt: .5 }}
              >
                {
                  statementPreInfoLoading
                    ? <CircularProgress size={18} />
                    : (
                      <Link href={`tel:${contact}`}>
                        {contact}
                      </Link>
                    )
                }
              </Typography>

              {
                isGuest
                  ? undefined
                  : (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ mt: 4, mb: 2 }}
                      >
                        <Iconify
                          icon="ic:round-list-alt"
                          fontSize={24}
                          sx={{ mr: 2 }}
                        />

                        <Typography variant="h6">
                          {translate('listApplications')}
                        </Typography>
                      </Stack>

                      {
                        statementPartialListLoading
                          ? <CircularProgress size={18} />
                          : (
                            partialList.length
                              ? <InvoiceShortList list={partialList} />
                              : <EmptyPlaceholder />
                          )
                      }

                      <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        disabled={!partialList.length}
                        sx={{ mt: 3 }}
                        onClick={onClickShowAllInvoices}
                      >
                        {translate('viewAll')}
                      </Button>
                    </>
                  )
              }
            </Card>
          </Stack>
        </PageGrid>

        {
          isGuest
            ? undefined
            : (
              <InvoiceListDialog
                list={list}
                page={page}
                pagesCount={pagesCount}
                loading={statementListLoading}
                onPageChange={onPageChange}
                open={invoiceDialogOpen}
                setOpen={setInvoiceDialogOpen}
              />
            )
        }
      </Page>
    </MainLayout>
  );
};

export default InvoiceLayout;