import { Box, BoxProps } from '@mui/material';
import { UserStatement } from '../../store/user-statement/types';
import React, { FC } from 'react';
import InvoiceListItem from './InvoiceListItem';

interface InvoiceListProps extends Pick<BoxProps, 'sx'> {
  list?: UserStatement[]
  onItemClick?: (item: UserStatement) => void
}

const InvoiceList: FC<InvoiceListProps> = ({
  sx,
  list,
  onItemClick,
}) => {
  return (
    <Box sx={sx}>
      {
        list?.map((item, index) => (
          <InvoiceListItem
            key={index}
            sx={{ mt: index ? 2 : 0 }}
            onClick={() => onItemClick && onItemClick(item)}
            {...item}
          />
        ))
      }
    </Box>
  );
};

export default InvoiceList;