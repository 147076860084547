import { createContext, FC, PropsWithChildren } from 'react';

const initialState = {
  disable: false
};

export const PageGridContext = createContext(initialState);

const PageGridProvider: FC<PropsWithChildren<typeof initialState>> = ({
  children,
  ...value
}) => {
  return (
    <PageGridContext.Provider value={value}>
      {children}
    </PageGridContext.Provider>
  );
};

export default PageGridProvider;