import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, sx, ...other }, ref) => {
  const { palette: { background } } = useTheme();
  const { translate } = useLocales();
  const pageTitle = title || translate('onlineLibrary');

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} | STARS E-Book`}</title>

        {meta}
      </Helmet>

      <Box
        ref={ref}
        sx={{
          mx: 'auto',
          position: 'relative',
          backgroundColor: background.page,
          ...(sx || {})
        }}
        {...other}
      >
        {children}
      </Box>
    </>
  );
});

export default Page;
