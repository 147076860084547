import { Outlet } from 'react-router-dom';
import MainFooter from './MainFooter';
import DashboardHeader from '../dashboard/header';
import NavbarHorizontal from '../dashboard/navbar/NavbarHorizontal';
import React, { FC, PropsWithChildren, ReactElement } from 'react';
import useAuthStore from '../../store/auth/auth.store';

interface MainLayoutProps {
  hideNavbar?: boolean
  navbar?: ReactElement
}

const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = ({
  hideNavbar,
  children,
  navbar
}) => {
  const {
    isAuth,
    verified
  } = useAuthStore(state => ({
    isAuth: state.isAuth,
    verified: state.verified
  }));

  return (
    <>
      <DashboardHeader verticalLayout isShadow={hideNavbar} />

      {(isAuth && verified && !hideNavbar)
        ? (navbar || <NavbarHorizontal />)
        : undefined}

      {children || <Outlet />}

      {isAuth ? <MainFooter /> : undefined}
    </>
  );
};

export default MainLayout;