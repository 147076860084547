import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { LocaleCrudApi } from './locale-crud.api';
import { LocaleFieldUpdate, LocaleLangStore } from './types';
import { addLocalesToApp, appLang, getDefaultLang, setAppLang } from '../../locales/i18n';
import useAuthStore from '../auth/auth.store';
import { findLang } from '../../hooks/useLocales';
import { defaultLang } from '../../config';

const setLocaleField = (
  set: any,
  get: any,
  field: LocaleFieldUpdate
) => {
  const { locale } = get();

  set({
    locale: {
      ...locale,
      [field.key]: {
        ...(locale[field.key] || {}),
        [field.lang]: field.value,
        type: field.type
      }
    }
  });
};

export const useLocaleCRUD = create(devtools<LocaleLangStore>((set, get) => ({
  iconsBaseUrl: '',
  locale: {},
  localeByLang: {},
  localeByLangLoading: false,
  localeByLangLoaded: false,
  appLanguages: [],
  availableLanguages: [],

  addLocaleField: async (
    field: LocaleFieldUpdate
  ) => {
    const addedField = await LocaleCrudApi.addLocaleField(field);

    addedField && setLocaleField(set, get, addedField);
  },

  updateLocaleField: async (
    field: LocaleFieldUpdate
  ) => {
    const updatedField = await LocaleCrudApi.updateLocaleField(field);

    updatedField && setLocaleField(set, get, updatedField);
  },

  addLangToApp: async (
    field: Partial<LocaleFieldUpdate>
  ) => {
    const addedLang = await LocaleCrudApi.addLangToApp(field);

    if (addedLang) {
      const { appLanguages, availableLanguages } = get();

      const availableLang = availableLanguages.find(item =>
        item.lang === addedLang.lang);

      availableLang && set({
        appLanguages: [
          ...appLanguages,
          { ...availableLang }
        ]
      });
    }
  },

  removeLangFromApp: async (
    field: Partial<LocaleFieldUpdate>
  ) => {
    const removedLang = await LocaleCrudApi.removeLangFromApp(field);

    if (removedLang) {
      set({
        appLanguages: get().appLanguages.filter(item =>
          item.lang !== removedLang.lang)
      });
    }
  },

  loadLocaleFieldsByLang: async (
    field: Partial<LocaleFieldUpdate>
  ) => {
    set({ localeByLangLoading: true });

    const localeByLang = await LocaleCrudApi.getLocaleFieldsByLang(
      field,
      useAuthStore.getState().isAdmin
    );

    localeByLang && set({
      localeByLang,
      localeByLangLoading: false,
      localeByLangLoaded: true
    });
  },

  loadAndSetLocaleFieldsByLang: async (
    field: Partial<LocaleFieldUpdate>
  ) => {
    appLang.lang = field.lang!;

    await get().loadLocaleFieldsByLang(field);

    addLocalesToApp(field.lang!, get().localeByLang);
    setAppLang(field.lang!);
  },

  loadLocaleFields: async () => {
    const locale = await LocaleCrudApi.getLocaleFields(
      useAuthStore.getState().isAdmin
    );

    locale && set({ locale });
  },

  loadAppLanguages: async () => {
    const result = await LocaleCrudApi.getAppLanguages(
      useAuthStore.getState().isAdmin
    );

    result && set({
      appLanguages: result.result,
      iconsBaseUrl: result.base_url
    });
  },

  loadAvailableLanguages: async () => {
    const result = await LocaleCrudApi.getAvailableLanguages(
      useAuthStore.getState().isAdmin
    );

    result && set({
      availableLanguages: result.result,
      iconsBaseUrl: result.base_url
    });
  },

  loadAndSetDefaultLanguage: async () => {
    await get().loadAppLanguages();

    const { appLanguages } = get();

    const lang = findLang(appLanguages, getDefaultLang())?.lang
      || findLang(appLanguages, defaultLang)?.lang
      || appLanguages[0]?.lang;

    await get().loadAndSetLocaleFieldsByLang({ lang });
  },
})));