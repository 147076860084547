import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { UserStatement, UserStatementCreated, UserStatementStore, UserStatementToCreate } from './types';
import { ConditionalExcept } from 'type-fest';
import { UserStatementApi } from './user-statement.api';
import { FileAttached } from '../../components/upload';

const PAGE_SIZE = 4;
const PARTIAL_PAGE_SIZE = 3;

const getInitialState = (): ConditionalExcept<UserStatementStore, Function> => ({
  contact: '',
  statementTypes: [],
  list: [],
  currentStatement: null,
  page:  1,
  pagesCount: 0,
  statementPreInfoLoading: false,
  statementListLoading: false,
  currentStatementLoading: false,
  partialList: [],
  statementPartialListLoading: false,
});

export const useUserStatementStore = create(devtools<UserStatementStore>((set, get) => ({
  ...getInitialState(),

  reset: () => {
    set(getInitialState());
  },

  setList: (list: UserStatement[]) => {
    set({ list });
  },

  setPage: (page: number) => {
    set({ page });
  },

  setPagesCount: (pagesCount: number) => {
    set({ pagesCount });
  },

  setCurrentStatement: (currentStatement: UserStatement | null) => {
    set({ currentStatement });
  },

  loadCurrentStatement: async (id: string) => {
    set({ currentStatementLoading: true });

    const currentStatement = await UserStatementApi.getById(id);

    set({
      currentStatementLoading: false,
      currentStatement,
    });
  },

  loadStatementList: async () => {
    set({ statementListLoading: true });

    const { page } = get();

    const response = await UserStatementApi.getList(
      { page, size: PAGE_SIZE }
    );

    set({
      statementListLoading: false,
      pagesCount: response?.pages || 0,
      list: response?.result || []
    });
  },

  loadStatementPartialList: async () => {
    set({ statementPartialListLoading: true });

    const response = await UserStatementApi.getList(
      { page: 1, size: PARTIAL_PAGE_SIZE }
    );

    set({
      statementPartialListLoading: false,
      partialList: (response?.result || []).slice(0, PARTIAL_PAGE_SIZE)
    });
  },

  loadStatementPreInfo: async () => {
    set({ statementPreInfoLoading: true });

    const response = await UserStatementApi.getPreInfo();

    set({
      statementPreInfoLoading: false,
      contact: response?.contact || '',
      statementTypes: response?.types || []
    });
  },

  createStatement: async (
    body: UserStatementToCreate
  ): Promise<UserStatementCreated | null> => {
    const response = await UserStatementApi.create(body);

    response && set({
      list: [ response, ...get().list.slice(0, PAGE_SIZE - 1) ]
    });

    return response;
  },

  attachFileToStatement: async (
    id: string,
    body: File
  ): Promise<FileAttached | null> =>
    UserStatementApi.attachFiles(id, body),
})));