import { AxiosRequestConfig } from 'axios';

export enum ApiResponseMessageType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  PLACEHOLDER = 'PLACEHOLDER',
}

export enum ApiResponseAction {
  refresh_token = 'refresh_token',
  logout = 'logout',
  verification = 'verification',
  default = 'default',
}

export interface ApiResponseMessage {
  message?: string | null
  type?: ApiResponseMessageType | null
  field?: string | null
  location?: string | null
  status_code?: number | null
}

export interface ApiResponse<T = any> {
  data?: T | null
  action?: ApiResponseAction | null
  messages?: ApiResponseMessage[]
  success?: boolean
}

export interface ApiRefreshTokenHandler {
  currentRequest: null | Promise<boolean>
  refreshToken: () => Promise<boolean>
}

export type ApiResponseHandlersByAction = {
  [P in ApiResponseAction]: (
    response: ApiResponse,
    config?: AxiosRequestConfig
  ) => Promise<ApiResponse | null>
}