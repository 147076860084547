import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import { PATH_PAGE } from '../routes/paths';
import { useNavigate } from 'react-router-dom';
import useLocales from '../hooks/useLocales';
import useTabResponsive from 'src/hooks/useTabResponsive';

const ContactHelp = () => {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const isTab = useTabResponsive();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: isTab ? 'flex-end' : 'center',
        justifyContent: 'center',
        pt: '70px',
      }}
    >
      <Typography
        sx={{ mb: 1.5 }}
        align={isTab ? 'right' : 'center'}
        fontWeight={600}
      >
        { translate('accessProblem') }
      </Typography>

      <Button
        variant="outlined"
        startIcon={<SmsIcon />}
        size="large"
        sx={{ textTransform: 'none' }}
        onClick={ () => navigate(PATH_PAGE.userInvoices) }
      >
        {translate('contactUniversity')}
      </Button>
    </Box>
  );
};

export default ContactHelp;