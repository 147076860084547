import { ApiResponse, ApiResponseMessageType } from '../@types/api';
import { useNotificationStore } from '../store/notification/notification.store';

export const addNotificationsFromApiResponse = (
  response: ApiResponse
) => {
  response?.messages?.forEach(
    item => {
      item.type !== ApiResponseMessageType.PLACEHOLDER
        && !item.field
        && item.message
        && useNotificationStore.getState().addNotification({
          type: item.type,
          message: item.message
        });
    }
  );
};