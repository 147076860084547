import React, { FC, useEffect } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { useNotificationStore } from '../store/notification/notification.store';
import { ApiResponseMessageType } from '../@types/api';

const notificationVariants: { [P in ApiResponseMessageType]?: VariantType } = {
  [ApiResponseMessageType.SUCCESS]: 'success',
  [ApiResponseMessageType.INFO]: 'info',
  [ApiResponseMessageType.WARNING]: 'warning',
  [ApiResponseMessageType.ERROR]: 'error',
};

const NotificationFromStore: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    deleteNotification,
    notificationList
  } = useNotificationStore(state => ({
    deleteNotification: state.deleteNotification,
    notificationList: state.notificationList
  }));

  useEffect(() => {
    notificationList.forEach(item => {
      enqueueSnackbar(item.message, {
        variant: (item.type && notificationVariants[item.type]) || 'default'
      });

      deleteNotification(item.id);
    });
  }, [notificationList]);

  return null;
};

export default NotificationFromStore;