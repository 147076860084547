import AuthLayout from '../../layouts/AuthLayout';
import FaceIdForm from '../../sections/auth/login/FaceIdForm';
import FaceIdPrepare from '../../sections/auth/login/FaceIdPrepare';
import { useState } from 'react';
import useLocales from '../../hooks/useLocales';

const FaceId = () => {
  const [ isPrepared, setIsPrepared ] = useState<boolean>(false);
  const { translate } = useLocales();

  const onPrepared = () => {
    setIsPrepared(true);
  };

  return (
    <AuthLayout pageTitle={translate('verification')}>
      {
        isPrepared
          ? <FaceIdForm />
          : <FaceIdPrepare onContinue={onPrepared} />
      }
    </AuthLayout>
  );
};

export default FaceId;