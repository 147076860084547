import { ChangeEvent, KeyboardEvent, useCallback, FC } from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import Iconify from '../../../components/Iconify';
import { useNavigate } from 'react-router-dom';
import { getPathByPageType } from '../../../routes/paths';
import { BookSelectionType } from '../../../store/books-crud/types';
import { useSearchStore } from '../../../store/search/search.store';
import useLocales from '../../../hooks/useLocales';
import { BoxProps, IconButton } from '@mui/material';
import classNames from 'classnames';

interface SearchbarProps extends Pick<BoxProps, 'sx'> {
  fixed?: boolean
  active?: boolean
  setActive?: (value: boolean) => void
}

const Searchbar: FC<SearchbarProps> = ({
  fixed,
  active,
  setActive
}) => {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const handleClose = () => {
    setActive && setActive(false);
  };

  const {
    searchText,
    setSearchText,
  } = useSearchStore(state => ({
    searchText: state.searchText,
    setSearchText: state.setSearchText,
  }));

  const onSearch = useCallback(
    () => {
      const filteredSearchText = searchText?.trim();

      filteredSearchText
        && navigate(getPathByPageType({
          page: {
            query: { [BookSelectionType.text]: filteredSearchText }
          },
          type: BookSelectionType.search
        }));
    },
    [searchText]
  );

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.keyCode === 13
      && onSearch();
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <Box
      sx={{
        width: 450,
        maxWidth: '100%',
        mr: 2,
        ...(fixed ? {
          position: 'absolute',
          left: 0,
          top: '-100%',
          zIndex: 2,
          backgroundColor: theme => theme.palette.common.white,
          width: '100%',
          height: '100%',
          opacity: .5,
          transition: '200ms ease-out',
          '&.active': {
            top: 8,
            opacity: 1
          }
        } : {})
      }}
      className={classNames({ active })}
    >
      <TextField
        value={searchText}
        fullWidth
        placeholder={translate('bookOrAuthor')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                color="#919EAB"
                icon="charm:search"
                width={24}
                height={24}
              />
            </InputAdornment>
          ),
          endAdornment: fixed ? (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={handleClose}
              >
                <Iconify
                  color="common.black"
                  icon="material-symbols:close-rounded"
                  width={20}
                  height={20}
                />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }}
        sx={fixed ? {
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none!important'
          },
          '.MuiInputBase-root.MuiOutlinedInput-root': {
            px: 3
          }
        } : undefined}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </Box>
  );
};

export default Searchbar;