import { PATH_DASHBOARD } from '../../../routes/paths';
import { ICONS } from './NavIcon';
import { NavSectionProps } from '../../../components/nav-section';
// import Label from '../../../components/Label';
// import Iconify from '../../../components/Iconify';

const navConfigAdmin: NavSectionProps['navConfig'] = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user
      },
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      // { title: 'list', path: PATH_DASHBOARD.user.list },
      // { title: 'create', path: PATH_DASHBOARD.user.new },
      // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      // { title: 'account', path: PATH_DASHBOARD.user.account },
      // ],
      // },

      // E-COMMERCE
      // {
      //   title: 'ecommerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //   ],
      // },

      // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },

  // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  // //      default roles : All roles can see this entry.
  // //      roles: ['user'] Only users can see this item.
  // //      roles: ['admin'] Only admin can see this item.
  // //      roles: ['admin', 'manager'] Only admin/manager can see this item.
  // //      Reference from 'src/guards/RoleBasedGuard'.
  //   title: 'item_by_roles',
  //   path: PATH_DASHBOARD.permissionDenied,
  //   icon: ICONS.menuItem,
  //   roles: ['admin'],
  //   caption: 'only_admin_can_see_this_item',
  // },
  // {
  //   title: 'menu_level_1',
  //   path: '#/dashboard/menu_level_1',
  //   icon: ICONS.menuItem,
  //   children: [
  //     { title: 'menu_level_2a', path: '#/dashboard/menu_level_1/menu_level_2a' },
  //     {
  //       title: 'menu_level_2b',
  //       path: '#/dashboard/menu_level_1/menu_level_2b',
  //       children: [
  //         {
  //           title: 'menu_level_3a',
  //           path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3a',
  //         },
  //         {
  //           title: 'menu_level_3b',
  //           path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b',
  //           children: [
  //             {
  //               title: 'menu_level_4a',
  //               path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b/menu_level_4a',
  //             },
  //             {
  //               title: 'menu_level_4b',
  //               path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b/menu_level_4b',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // { title: 'item_disabled', path: '#disabled', icon: ICONS.menuItem, disabled: true },
  // {
  //   title: 'item_label',
  //   path: '#label',
  //   icon: ICONS.menuItem,
  //   info: (
  //     <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //       NEW
  //     </Label>
  //   ),
  // },
  // {
  //   title: 'item_caption',
  //   path: '#caption',
  //   icon: ICONS.menuItem,
  //   caption:
  //     'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  // },
  // { title: 'item_external_link', path: 'https://www.google.com/', icon: ICONS.menuItem },
  //   ],
  // },
];

export default navConfigAdmin;