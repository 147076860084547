import { Box, BoxProps, CircularProgress, Stack } from '@mui/material';
import { FC, useCallback, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import cropAndResizeCanvasSource from '../../../utils/crop-canvas-source';
import { useIntervalPercent } from '../../../hooks/useIntervalPercent';
import useAuthStore from '../../../store/auth/auth.store';
import { FaceIdSocketListener } from '../../../store/auth/types';
import { useCameraSize } from '../../../hooks/useCameraSize';
import { useReCaptcha } from '../../../hooks/useReCaptcha';

interface FaceIdWebcamProps extends Pick<BoxProps, 'sx'> {}

const IMAGE_SIZE = 240;
const IMAGE_FORMAT = 'image/jpeg';
const IMAGE_QUALITY = .95;

const FaceIdWebcam: FC<FaceIdWebcamProps> = ({
  sx
}) => {
  const webcamRef = useRef<Webcam | null>(null);
  const faceIdRef = useRef<Pick<FaceIdSocketListener, 'stopFaceId'> | null>(null);
  const { startIntervalPercent, intervalPercent } = useIntervalPercent();
  const generateReCaptcha = useReCaptcha();

  const {
    webcamWidth,
    webcamSize,
    webcamHeight,
    webcamFullSize
  } = useCameraSize();

  const {
    subscribeToFaceId
  } = useAuthStore(state => ({
    subscribeToFaceId: state.subscribeToFaceId
  }));

  const getImageFromVideo = useCallback(
    async (): Promise<Blob | null> => {
      if (!webcamRef.current?.video) return null;

      const webcamInputSize = Math.min(
        webcamRef.current.video.videoWidth,
        webcamRef.current.video.videoHeight,
        webcamFullSize
      );

      const source = cropAndResizeCanvasSource(
        webcamRef.current.video,
        webcamRef.current.video.videoWidth,
        webcamRef.current.video.videoHeight,
        webcamInputSize,
        webcamInputSize,
        IMAGE_SIZE,
      );

      return new Promise(resolve =>
        source.toBlob(resolve, IMAGE_FORMAT, IMAGE_QUALITY));
    },
    [webcamFullSize]
  );

  const subscribeToFaceIdSocket = async () => {
    const faceIdSubscription = await subscribeToFaceId(
      getImageFromVideo,
      generateReCaptcha
    );

    if (!faceIdSubscription) return;

    faceIdRef.current = { stopFaceId: faceIdSubscription.stopFaceId };

    startIntervalPercent(faceIdSubscription.faceIdTimeout);
  };

  const onUserMedia = (stream: MediaStream) => {
    stream.active && subscribeToFaceIdSocket();
  };

  useEffect(() => {
    if (webcamRef.current?.video) {
      webcamRef.current.video.disablePictureInPicture = true;
    }

    return () => faceIdRef.current?.stopFaceId();
  }, []);

  return (
    <Box
      sx={{
        width: webcamSize,
        height: webcamSize,
        maxWidth: '100%',
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        overflow: 'hidden',
        position: 'relative',
        transform: 'translateZ(0)',
        ...(sx || {})
      }}
    >
      <CircularProgress
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 2,
          width: '100%!important',
          height: '100%!important'
        }}
        variant="determinate"
        value={intervalPercent}
        thickness={.5}
      />

      <Stack
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          zIndex: 1,
          transform: 'scaleX(-1)'
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Webcam
          audio={false}
          ref={webcamRef}
          width={webcamWidth}
          height={webcamHeight}
          videoConstraints={{
            width: webcamWidth,
            height: webcamHeight,
            facingMode: "user"
          }}
          onUserMedia={onUserMedia}
          style={{ position: 'relative' }}
        />
      </Stack>
    </Box>
  );
};

export default FaceIdWebcam;