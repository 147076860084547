export default function resize2DCanvas(
  resizingCanvas: HTMLCanvasElement,
  width: number
): HTMLCanvasElement {
  const resizingCanvasContext = resizingCanvas.getContext('2d');

  const curImageDimensions = {
    width: Math.floor(resizingCanvas.width),
    height: Math.floor(resizingCanvas.height)
  };

  const halfImageDimensions = { width: 0, height: 0 };

  const drawImageToCanvasContext = (
    canvasContext: CanvasRenderingContext2D | null,
    size: Partial<typeof halfImageDimensions>
  ) => {
    canvasContext?.drawImage(
      resizingCanvas,
      0,
      0,
      curImageDimensions.width,
      curImageDimensions.height,
      0,
      0,
      size?.width || 0,
      size?.height || size?.width || 0
    );
  };

  while (curImageDimensions.width * 0.5 > width) {
    halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
    halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

    drawImageToCanvasContext(resizingCanvasContext, halfImageDimensions);

    curImageDimensions.width = halfImageDimensions.width;
    curImageDimensions.height = halfImageDimensions.height;
  }

  const outputCanvas = document.createElement('canvas');

  outputCanvas.width = width;
  outputCanvas.height = width;

  drawImageToCanvasContext(outputCanvas.getContext("2d"), { width });

  return outputCanvas;
}