import useAuthStore from '../store/auth/auth.store';
import { useLayoutEffect } from 'react';
import Cookies from 'js-cookie';

export const useAuthInit = () => {
  const {
    loadMe,
    setIsAuth
  } = useAuthStore(state => ({
    loadMe: state.loadMe,
    setIsAuth: state.setIsAuth
  }));

  useLayoutEffect(() => {
    if (Cookies.get('is_auth')) {
      setIsAuth(true);
      loadMe();
    }
  }, []);
};