import { useTranslation } from 'react-i18next';
import useSettings from './useSettings';
import { defaultLang } from '../config';
import { useLocaleCRUD } from '../store/locale-crud/locale-crud.store';
import { LocaleLang } from '../store/locale-crud/types';
import { appLang } from '../locales/i18n';

export const findLang = (
  langList: LocaleLang[],
  lang: string | null
): LocaleLang | undefined => lang
  ? langList.find(langItem => langItem.lang === lang)
  : undefined;

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { onChangeDirectionByLang } = useSettings();

  const {
    appLanguages,
    loadAndSetLocaleFieldsByLang,
    localeByLangLoaded,
  } = useLocaleCRUD(state => ({
    appLanguages: state.appLanguages,
    loadAndSetLocaleFieldsByLang: state.loadAndSetLocaleFieldsByLang,
    localeByLangLoaded: state.localeByLangLoaded
  }));

  const currentLang = findLang(appLanguages, i18n.language)
    || { lang: defaultLang, icon: `/assets/icons/flags/${defaultLang}.svg` };

  const handleChangeLanguage = (newlang: string) => {
    if (localeByLangLoaded && newlang === appLang.lang)
      return;

    loadAndSetLocaleFieldsByLang({ lang: newlang });
    onChangeDirectionByLang(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => translate(text, options),
    currentLang,
    allLangs: appLanguages,
  };
}
