import useAuthStore from '../store/auth/auth.store';
import navConfigAdmin from '../layouts/dashboard/navbar/NavConfigAdmin';
import useNavConfigClient from '../layouts/dashboard/navbar/NavConfigClient';

export const useNavConfig = (): typeof navConfigAdmin => {
  const {
    isAdmin
  } = useAuthStore(state => ({
    isAdmin: state.isAdmin
  }));

  const navConfigClient = useNavConfigClient();

  return isAdmin ? navConfigAdmin : navConfigClient;
};