import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { PagesStore } from './types';

export const usePagesStore = create(devtools<PagesStore>((set, get) => ({
	pageRefreshCount: 0,

	increasePagesRefreshCount: () => {
		set({
			pageRefreshCount: get().pageRefreshCount + 1
		});
	}
})));