import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { Container, AppBar } from '@mui/material';
import { HEADER } from '../../../config';
import { NavSectionHorizontal } from '../../../components/nav-section';
import useSettings from '../../../hooks/useSettings';
import { useNavConfig } from '../../../hooks/useNavConfig';
import useResponsive from 'src/hooks/useResponsive';

const RootStyle = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  padding: '12px 0 8px 0',
  boxShadow: theme.customShadows.z8,
  top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('sm')]: {
    top: HEADER.MOBILE_HEIGHT
  }
}));

function NavbarHorizontal() {
  const { gridWidthStyle } = useSettings();
  const navConfig = useNavConfig();
  const isCustomMobile = useResponsive('down', 'sm');

  return (
    <>
      <RootStyle>
        <Container sx={{ ...gridWidthStyle }}>
          <NavSectionHorizontal navConfig={navConfig} />
        </Container>
      </RootStyle>

      <div style={{ marginTop: isCustomMobile ? 132 : 148 }} />
    </>
  );
}

export default memo(NavbarHorizontal);
