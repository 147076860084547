import api from '../../utils/api';
import {
  AuthUserSettings,
  FaceIdResponseAction, FaceIdSocketListener,
  FaceIdSocketResponse, FaceIdSocketSource, FaceIdTokenDTO,
  LoginDTO,
  LoginQrId,
  LoginWithQrPolling,
  ResetPasswordDTO,
  ResetPasswordSecret,
  ResetPasswordVerifyDTO,
} from './types';
import axios from 'axios';
import { ApiResponse } from '../../@types/api';
import { WS_API } from '../../config';

export class AuthApi {

  static FACE_ID_INTERVAL_IN_SECONDS: number = 5

  static async login(loginDto: LoginDTO): Promise<boolean> {
    const result = await api.post(
      'auth/login', loginDto
    ) as ApiResponse;

    return result.success || false;
  }

  static async logout(): Promise<boolean> {
    const result = await api.delete(
      'auth/logout'
    ) as ApiResponse;

    return result.success || false;
  }

  static async loadMe(): Promise<AuthUserSettings | null> {
    const result = await api.get(
      'auth/me'
    ) as ApiResponse;

    return result.data || null;
  }

  static async generateQrId(): Promise<LoginQrId | null> {
    const response = await api.get('auth/qr') as ApiResponse;

    return response?.data;
  }

  static subscribeToQrActivation(id: string): LoginWithQrPolling {
    const source = axios.CancelToken.source();

    const request = api
      .post('auth/qr/polling', { id }, {
        cancelToken: source.token
      })
      .then((response: ApiResponse) => response?.success || false);

    return {
      cancel: () => source.cancel(),
      request
    };
  }

  static async activateQr(id: string): Promise<boolean> {
    const result = await api.post(
      'auth/qr/polling/activate', { id }
    ) as ApiResponse;

    return result?.success || false;
  }

  static async resetPassword(
    body: ResetPasswordDTO
  ): Promise<ResetPasswordSecret | null> {
    const response = await api.post(
      'auth/reset-password',
      body
    ) as ApiResponse;

    return response?.data || null;
  }

  static async verifyResetPassword(
    body: ResetPasswordVerifyDTO
  ): Promise<boolean> {
    const response = await api.post(
      'auth/reset-password/verify',
      body
    ) as ApiResponse;

    return response?.success || false;
  }

  static async register(
    body: ResetPasswordDTO
  ): Promise<ResetPasswordSecret | null> {
    const response = await api.post(
      'auth/validate_username',
      body
    ) as ApiResponse;

    return response?.data || null;
  }

  static async verifyRegister(
    body: ResetPasswordVerifyDTO
  ): Promise<boolean> {
    const response = await api.post(
      'auth/verify_otp',
      body
    ) as ApiResponse;

    return response?.success || false;
  }

  static async refreshToken(): Promise<boolean> {
    const response = await api.post('auth/refresh') as ApiResponse;

    return response?.success || false;
  }

  static async verifyFaceIdToken(
    body: FaceIdTokenDTO,
    captcha: string
  ): Promise<boolean> {
    const response = await api.post(
      'auth/live-stream/verify?captcha=' + captcha,
      body
    ) as ApiResponse;

    return response?.success || false;
  }

  static async subscribeToFaceId(
    dataGetter: () => Promise<FaceIdSocketSource>,
    captcha: string,
    onMessage?: (data: FaceIdSocketResponse) => void
  ): Promise<FaceIdSocketListener> {
    return new Promise(resolve => {
      let intervalId: NodeJS.Timer;

      const socket = new WebSocket(
        WS_API + 'auth/live-stream?captcha=' + captcha
      );

      const callOnMessage = (data: FaceIdSocketResponse) =>
        onMessage && onMessage(data);

      const stopInterval = () => intervalId && clearInterval(intervalId);

      const stopFaceId = () => {
        stopInterval();
        socket?.close();
      };

      const sendData = async () => {
        const data = await dataGetter();

        data && socket.send(data);
      };

      const startSendingDataAtIntervals = (intervalInSeconds: number) => {
        stopInterval();

        intervalId = setInterval(
          sendData,
          (intervalInSeconds || AuthApi.FACE_ID_INTERVAL_IN_SECONDS) * 1000
        );
      };

      socket.addEventListener('open', sendData);

      socket.addEventListener('message', event => {
        if (!event.data) return;

        try {
          const data: FaceIdSocketResponse = JSON.parse(event.data);

          callOnMessage(data || {});

          if (data?.result?.count && data?.result?.timeout) {
            startSendingDataAtIntervals(
              data.result.timeout / data.result.count
            );

            resolve({
              stopFaceId,
              faceIdTimeout: data.result.timeout
            });
          }

          if (data?.action === FaceIdResponseAction.stop
            || data?.action === FaceIdResponseAction.verified) {
            stopFaceId();
          }
        } catch (error) {
          console.warn('Face ID WS Error', error);
        }
      });
    });
  }

}