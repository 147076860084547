import React, { FC } from 'react';
import { CircularProgress, Stack, StackProps } from '@mui/material';

interface PageLoadingProps extends StackProps {
  loading?: boolean
  dense?: boolean
}

const PageLoading: FC<PageLoadingProps> = ({
  loading,
  sx,
  dense,
  ...otherProps
}) => {
  return loading
    ? (
      <Stack
        alignItems="center"
        sx={{ pt: dense ? 4 : 6, pb: dense ? 0 : 2, ...(sx || {}) }}
        {...otherProps}
      >
        <CircularProgress size={dense ? 28 : 40} />
      </Stack>
    )
    : null;
};

export default PageLoading;