import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, BoxProps, IconButton } from '@mui/material';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useTabResponsive from '../../../hooks/useTabResponsive';
import { HEADER } from '../../../config';
import Logo from '../../../components/logo/Logo';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';
import LogoFull from '../../../components/logo/LogoFull';
import useSettings from '../../../hooks/useSettings';
import useAuthStore from '../../../store/auth/auth.store';
import Searchbar from './Searchbar';
import useResponsive from 'src/hooks/useResponsive';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import { useUpdate } from 'src/hooks/useUpdate';
import { useLocation } from 'react-router-dom';

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<Props>(({ isOffset, verticalLayout, theme }) => ({
  alignItems: 'center',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    paddingTop: 12,
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
}));

type Props = Pick<BoxProps, 'sx'> & {
  onOpenSidebar?: VoidFunction;
  isCollapse?: boolean;
  isOffset?: boolean;
  verticalLayout?: boolean;
  isShadow?: boolean;
};

export default function DashboardHeader({
  isCollapse = false,
  verticalLayout = false,
  sx,
  isShadow,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isTab = useTabResponsive();
  const isCustomTab = useResponsive('down', 700);
  const { gridWidthStyle } = useSettings();
  const [ searchBarIsActive, setSearchBarIsActive ] = useState<boolean>(false);
  const { pathname } = useLocation();

  const {
    isAuth,
    isAdmin
  } = useAuthStore(state => ({
    isAuth: state.isAuth && state.verified,
    isAdmin: state.isAdmin
  }));

  const handleOpenSearchBar = () => {
    setSearchBarIsActive(true);
  };

  useUpdate(() => {
    setSearchBarIsActive(false);
  }, [pathname]);

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
      sx={{
        boxShadow: theme =>
          isShadow ? theme.customShadows.z8 : 'none',
        ...(isShadow && { paddingTop: '0!important' }),
        ...(sx || {})
      }}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          ...gridWidthStyle,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ mr: 2 }}
        >
          {
            verticalLayout && (
              (isTab || !isAuth)
                ? (
                  <LogoFull
                    sx={{
                      maxWidth: '100%',
                      svg: { maxWidth: '100%' }
                    }}
                  />
                )
                : (<Logo />)
            )
          }

          {
            isCustomTab && isAuth
              ? (
                <IconButton
                  sx={{ ml: 1.5 }}
                  onClick={handleOpenSearchBar}
                >
                  <Iconify
                    color="#637381"
                    icon="charm:search"
                    width={20}
                    height={20}
                  />
                </IconButton>
              )
              : undefined
          }
        </Stack>

        {
          (!isAdmin && isAuth)
            ? (
              <Searchbar
                active={searchBarIsActive}
                setActive={setSearchBarIsActive}
                fixed={isCustomTab}
              />
            )
            : undefined
        }

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <LanguagePopover />

          {
            isAuth
              ? (
                <>
                  {/*<NotificationsPopover />*/}
                  <AccountPopover />
                </>
              )
              : undefined
          }
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
