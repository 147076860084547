export const getRandomIntInclusive = (
  min: number = 1,
  max: number = 10000
): number => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getUniqueAppNumber = ((value) => () => value++)(1);

export const getUniqueAppString = () => `id_${getUniqueAppNumber()}`;