import { useLocaleCRUD } from '../store/locale-crud/locale-crud.store';
import { useLayoutEffect } from 'react';

export const useLocaleInit = () => {
  const {
    loadAndSetDefaultLanguage
  } = useLocaleCRUD(state => ({
    loadAndSetDefaultLanguage: state.loadAndSetDefaultLanguage,
  }));

  useLayoutEffect(() => {
    loadAndSetDefaultLanguage();
  }, []);
};