import { getPathByPageType, STATIC_LINKS } from '../../../routes/paths';
import { NavSectionProps } from '../../../components/nav-section';
import useLocales from '../../../hooks/useLocales';
import { BookSelectionType } from '../../../store/books-crud/types';

const useNavConfigClient = (): NavSectionProps['navConfig'] => {
  const { translate, currentLang } = useLocales();

  return [
    {
      subheader: 'general',
      items: [
        {
          title: translate('home'),
          path: '/',
          icon: 'ic:round-home'
        },
        {
          title: translate('educationalPlans'),
          icon: 'ic:round-calendar-month',
          children: [
            {
              title: translate('correspondence'),
              href: STATIC_LINKS.correspondence(currentLang.lang),
              icon: 'fluent:document-pdf-32-regular'
            },
            {
              title: translate('correspondenceWinter'),
              href: STATIC_LINKS.correspondenceWinter(currentLang.lang),
              icon: 'fluent:document-pdf-32-regular'
            },
            {
              title: translate('fullTimeNational'),
              href: STATIC_LINKS.fullTime(currentLang.lang),
              icon: 'fluent:document-pdf-32-regular'
            },
            {
              title: translate('fullTimeNationalWinter'),
              href: STATIC_LINKS.fullTimeWinter(currentLang.lang),
              icon: 'fluent:document-pdf-16-regular'
            },
            {
              title: translate('fullTimeInternational'),
              href: STATIC_LINKS.fullTimeInternational(currentLang.lang),
              icon: 'fluent:document-pdf-32-regular'
            },
            {
              title: translate('fullTimeInternationalWinter'),
              href: STATIC_LINKS.fullTimeInternationalWinter(currentLang.lang),
              icon: 'fluent:document-pdf-16-regular'
            },
          ]
        },
        {
          title: translate('tutorials'),
          path: getPathByPageType({
            page: { endpoint: 'edu-books-page' },
            type: BookSelectionType.book_list
          }),
          icon: 'material-symbols:book-outline-rounded'
        },
        {
          title: translate('generalLiterature'),
          path: getPathByPageType({
            page: { endpoint: 'gen-books-page' },
            type: BookSelectionType.book_list
          }),
          icon: 'wpf:books'
        },
        {
          title: translate('news'),
          path: getPathByPageType({
            page: { endpoint: 'new-books-page' },
            type: BookSelectionType.book_list
          }),
          icon: 'majesticons:paper-fold-text-line'
        },
      ],
    },
  ];
};

export default useNavConfigClient;