import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      sx={{
        display: 'block',
        width: 40,
        height: 40,
        ...sx
      }}
      component={disabledLink ? 'div' : RouterLink}
      to="/"
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.09586 5.30319L25.4114 0V23.454C25.4114 23.8629 25.336 24.2321 25.2265 24.5507C25.1169 24.8693 24.9728 25.1379 24.7834 25.4013C24.6129 25.6385 24.4073 25.8522 24.1693 26.0481C24.0163 26.1739 23.8539 26.2825 23.6638 26.3849C23.5235 26.4606 23.3654 26.5375 23.1969 26.5933L6.5625 32V8.76972C6.5625 8.45725 6.5728 8.15367 6.63335 7.86242C6.72815 7.40673 6.91527 6.98847 7.18032 6.61454C7.44536 6.24067 7.78876 5.9107 8.2354 5.65609C8.48779 5.5122 8.77945 5.40129 9.09586 5.30319H9.09586Z"
          fill={PRIMARY_MAIN}
        />

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6069 29.0603V31.4516L23.7729 26.3238L23.772 26.3243C23.7369 26.3448 23.7009 26.365 23.6638 26.385C23.5235 26.4607 23.3654 26.5376 23.1969 26.5934L15.6069 29.0603H15.6069Z"
          fill={PRIMARY_DARK}
        />

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8927 6.99189L20.8967 10.9196C19.6464 11.3262 18.3632 11.7438 17.1016 12.1542L15.608 10.0958V12.6379L13.1879 13.4238L15.608 14.21V16.7527L17.1014 14.6969L19.0269 15.3212C19.8846 15.6153 20.4577 16.101 20.8408 16.6666C21.2695 17.2997 21.4576 18.0338 21.4833 18.7285C21.5007 19.0884 21.4654 19.4501 21.3876 19.7999C21.2486 20.4252 20.9631 21.0176 20.519 21.4968C20.1337 21.9124 19.6279 22.2419 19.0087 22.445L18.9994 22.4481V22.4481L11.1148 25.008V21.0728L18.7897 18.5828L13.1049 16.7358C12.9816 16.6958 12.8611 16.6562 12.7474 16.6116C12.5756 16.5441 12.4168 16.4679 12.2688 16.3841C11.8742 16.1605 11.5621 15.8816 11.3139 15.5821C10.9228 15.1102 10.6941 14.5652 10.5801 14.0122C10.5507 13.8695 10.5281 13.7262 10.5136 13.5834C10.5034 13.4838 10.4981 13.3842 10.4932 13.2859C10.4813 13.0402 10.4969 12.7941 10.5293 12.5512C10.5924 12.0785 10.7344 11.6176 10.9655 11.2008C11.1565 10.8562 11.4044 10.5387 11.7128 10.2702C12.0597 9.96805 12.4826 9.72708 12.9771 9.56626L20.8927 6.99188L20.8927 6.99189Z"
          fill="white"
        />

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1877 13.4241L15.6076 14.21L15.6079 16.7527L17.1013 14.6969C17.7431 14.905 18.2649 15.0742 18.9068 15.2823C19.2202 15.3972 19.4393 15.5782 19.5907 15.7866C19.7346 15.9849 19.822 16.2075 19.866 16.4227C19.9074 16.6248 19.9101 16.8206 19.9024 16.9812C19.8963 17.1093 19.8715 17.2483 19.8336 17.3875C19.7622 17.6499 19.6336 17.9179 19.4411 18.1402C19.2738 18.3335 19.0582 18.4923 18.7897 18.5828L13.1048 16.7356C12.7707 16.627 12.5392 16.4346 12.379 16.2096C12.2454 16.0219 12.1606 15.8118 12.1151 15.6048C12.0712 15.4054 12.0612 15.21 12.0717 15.0438C12.0842 14.8458 12.1292 14.609 12.2251 14.3759C12.2891 14.2204 12.379 14.0662 12.4851 13.9275C12.5774 13.8068 12.687 13.7034 12.814 13.6138C12.9248 13.5356 13.0487 13.4665 13.1877 13.4241Z"
          fill="url(#main-logo-linear-gradient)"
        />

        <defs>
          <linearGradient
            id="main-logo-linear-gradient"
            x1="12.0679"
            y1="16.0035"
            x2="19.9058"
            y2="16.0035"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F1D4D9" />
            <stop offset="0.290196" stopColor="white" />
            <stop offset="0.729412" stopColor="white" />
            <stop offset="1" stopColor="#F1D4D9" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
