import { deviceInfo } from '../utils/deviceInfo';

const WEBCAM_DEFAULT_SIZE = 386;
const FIREFOX_FACTOR = 1.5;
const FIREFOX_SIZE = 40;

export const useCameraSize = (
  initialSize: number = WEBCAM_DEFAULT_SIZE
) => {
  const webcamSize = Math.min(initialSize, window.innerWidth - 48);
  const webcamHeight = deviceInfo.isFirefox ? webcamSize * FIREFOX_FACTOR : webcamSize;
  const webcamWidth = deviceInfo.isMobile ? webcamSize : webcamHeight;

  const webcamFullSize = deviceInfo.isFirefox && !deviceInfo.isMobile
    ? webcamSize + FIREFOX_SIZE
    : webcamSize;

  return {
    webcamFullSize,
    webcamHeight,
    webcamSize,
    webcamWidth
  };
};