import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Login from '../pages/auth/Login';
import useAuthStore from '../store/auth/auth.store';
import Cookies from 'js-cookie';

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuth } = useAuthStore(state => ({
    isAuth: state.isAuth
  }));

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isAuth && !Cookies.get('is_auth')) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
