import resize2DCanvas from './resize-2d-canvas';

export default function cropAndResizeCanvasSource(
  source: CanvasImageSource,
  originalWidth: number,
  originalHeight: number,
  inputWidth: number,
  inputHeight: number,
  outputWidth: number,
): HTMLCanvasElement {
  return resize2DCanvas(cropCanvasSource(
    source,
    originalWidth,
    originalHeight,
    inputWidth,
    inputHeight,
  ), outputWidth);
}

export function cropCanvasSource(
  source: CanvasImageSource,
  originalWidth: number,
  originalHeight: number,
  inputWidth: number,
  inputHeight: number,
): HTMLCanvasElement {
  const canvas = document.createElement('canvas');

  canvas.width = inputWidth;
  canvas.height = inputHeight;

  const ctx = canvas.getContext('2d');

  const left = inputWidth < originalWidth
    ? -((originalWidth / 2) - (inputWidth / 2))
    : 0;

  const top = inputHeight < originalHeight
    ? -((originalHeight / 2) - (inputHeight / 2))
    : 0;

  ctx?.drawImage(source, left, top);

  return canvas;
}