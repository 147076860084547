import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import MainLayout from '../layouts/main';
import GuestGuard from '../guards/GuestGuard';
import InvoiceLayout from '../pages/invoice/InvoiceLayout';
import FaceIdGuard from '../guards/FaceIdGuard';
import { useLocaleCRUD } from '../store/locale-crud/locale-crud.store';
import BookReaderNavbar from '../components/book/reader/BookReaderNavbar';

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function RouterWithLoading() {
  const {
    localeByLangLoaded
  } = useLocaleCRUD(state => ({
    localeByLangLoaded: state.localeByLangLoaded
  }));

  return localeByLangLoaded
    ? <Router />
    : <LoadingScreen />;
}

export function Router() {
  return useRoutes([
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/account" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },
    {
      path: '*',
      element: <MainLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <FaceIdGuard />,
      children: [
        {
          path: 'auth',
          element: <GuestGuard />,
          children: [
            { path: 'login', element: <Login />, },
            { path: 'register', element: <Register />, },
            { path: 'reset-password', element: <ResetPassword /> },
          ],
        },
        {
          path: '/',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            { element: <HomePage />, index: true },
            { path: 'book/:id', element: <BookDetail /> },
            { path: 'book/search', element: <BooksSearch /> },
            { path: 'book/list/:endpoint', element: <BookListPage /> },
            { path: 'account', element: <ClientAccount /> },
          ],
        },
        {
          path: 'book/:id/read',
          element: (
            <AuthGuard>
              <MainLayout
                //navbar={<BookReaderNavbar />}
              />
            </AuthGuard>
          ),
          children: [
            { element: <BookReader />, index: true },
          ],
        }
      ]
    },
    {
      path: 'statement',
      element: <InvoiceLayout />,
      children: [
        { element: <InvoiceCreate />, index: true },
        { element: <InvoiceDetails />, path: ':id' }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/auth/PermissionDenied')));

const UserProfile = Loadable(lazy(() => import('../pages/dashboard/user/UserProfile')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/user/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/user/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/user/UserCreate')));

const HomePage = Loadable(lazy(() => import('../pages/Home')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const BookDetail = Loadable(lazy(() => import('../pages/BookDetail')));
const BookListPage = Loadable(lazy(() => import('../pages/BookListPage')));
const BooksSearch = Loadable(lazy(() => import('../pages/BooksSearch')));
const BookReader = Loadable(lazy(() => import('../pages/BookReader')));
const ClientAccount = Loadable(lazy(() => import('../pages/user/UserAccount')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/invoice/InvoiceCreate')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/invoice/InvoiceDetails')));