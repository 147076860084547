import { usePagesStore } from "src/store/pages/pages.store";
import { useUpdate } from "./useUpdate";

export const usePageOnRefresh = (callback: () => void) => {
	const pageRefreshCount = usePagesStore(state => state.pageRefreshCount);

	useUpdate(callback, [pageRefreshCount]);
};

export const usePageRefresh = () => {
	return usePagesStore(state => state.increasePagesRefreshCount);
};