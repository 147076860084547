import React, { FC } from 'react';
import { Pagination, PaginationProps, StackProps, useTheme } from '@mui/material';
import PageLoading from '../loading/PageLoading';
import useResponsive from 'src/hooks/useResponsive';

interface ListPaginationProps extends PaginationProps {
  setPage?: (page: number) => void
  pages?: number
  bg_color?: string
  loading?: boolean
  loadingSx?: StackProps['sx']
}

const ListPagination: FC<ListPaginationProps> = ({
  sx,
  setPage,
  page,
  pages,
  bg_color,
  loading,
  loadingSx,
  disabled,
}) => {
  const { palette } = useTheme();
  const backgroundColor = bg_color || palette.common.white;
  const isMobile = useResponsive('down', 'sm');

  const onPageChange = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    newPage !== page && setPage && setPage(newPage);
  };

  return (
    <>
      <PageLoading
        loading={loading}
        dense
        sx={loadingSx}
      />

      {
        !pages ? undefined : (
          <Pagination
            sx={{
              py: 1,
              px: .5,
              borderRadius: '8px',
              backgroundColor,
              '& .MuiPagination-ul': {
                justifyContent: 'center',
                ...(isMobile ? {
                  '.MuiPaginationItem-ellipsis': {
                    minWidth: 'auto',
                    px: .125,
                    mx: .25
                  }
                } : {})
              },
              ...(sx || {})
            }}
            count={pages}
            page={page}
            size={isMobile ? 'medium' : 'large'}
            siblingCount={isMobile ? 0 : 1}
            disabled={loading || disabled}
            onChange={onPageChange}
          />
        )
      }
    </>
  );
};

export default ListPagination;