import { PageType } from '../../@types/page';
import { WithAnyProps } from '../../@types/common';

export enum BookSelectionType {
  ads = 'ads',
  selection = 'selection',
  single_selection = 'single_selection',
  carousel = 'carousel',
  multi_selection = 'multi_selection',
  book = 'book',
  text = 'text',
  author = 'author',
  publisher = 'publisher',
  main = 'main',
  book_read = 'book_read',
  invoice_details = 'invoice_details',
  section = 'section',
  category = 'category',
  book_item = 'book_item',
  covers_carousel = 'covers_carousel',
  book_list = 'book_list',
  search = 'search',
  default = 'default',
  links_list = 'links_list',
}

export interface BookSelection extends BookSelectionListItem {
  list?: BookSelectionListItem[]
}

export interface BookSelectionPage {
  endpoint?: string
  query?: WithAnyProps
}

export interface BookSelectionListItem {
  id?: string
  title?: string
  cover_url?: string
  url?: string
  author?: string
  heading?: string
  subtitle?: string
  short_description?: string
  info?: PageType[]
  page?: BookSelectionPage
  type?: BookSelectionType
}

export interface BooksStore {}

export interface BookSelectionTab {
  value?: BookSelectionType
  label?: string
  count?: string
}

export type RouteByBookSelectionType = {
  [P in BookSelectionType]?: (pageType: PageType) => string
}