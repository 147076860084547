import { ApiResponseMessageType } from '../../@types/api';

export enum FaceIdResponseAction {
  verified = 'verified',
  stop = 'stop',
}

export interface FaceIdTokenDTO {
  token: string
}

export interface LoginDTO {
  username: string
  password: string
}

export interface LoginWithQrPolling {
  cancel: () => void
  request: Promise<boolean>
}

export interface AuthUserSettings {
  session_deactivate?: number
  full_name?: string
  email?: string
  cover_url?: string
}

export interface LoginQrId {
  id: string
  expire_in: number
}

export interface ResetPasswordDTO extends Pick<LoginDTO, 'username'> {}

export interface ResetPasswordVerifyDTO extends LoginDTO {
  secret: string
  otp: string
}

export interface ResetPasswordSecret extends Pick<ResetPasswordVerifyDTO, 'secret'> {
  phone?: string
}

export interface FaceIdSocketListener {
  stopFaceId: () => void
  faceIdTimeout: number
}

export interface FaceIdResponseMessage {
  text?: string
  type?: ApiResponseMessageType
}

export interface FaceIdSocketResponse {
  action?: FaceIdResponseAction
  message?: FaceIdResponseMessage
  result?: FaceIdSocketResponseResult
}

export interface FaceIdSocketResponseResult {
  token?: string
  count?: number
  timeout?: number
}

export interface ResetPasswordState {
  phoneForResetPassword: string
  secretForResetPassword: string
  loginForResetPassword: string
  newPasswordForReset: string
}

export interface RegisterState {
  phoneForRegister: string
  loginForRegister: string
  passwordForRegister: string
  secretForRegister: string
}

export interface AuthState {
  isAuth: boolean
  verified: boolean
  isAdmin: boolean
  email: string
  fullName: string
  avatarUrl: string
  sessionDeactivate: number
  meLoading: boolean
  verificationText: string
}

export interface AuthStore extends ResetPasswordState, RegisterState, AuthState {
  qrLoading: boolean
  qrId: string
  resetPasswordData: () => void
  resetAuthData: () => void
  resetRegisterData: () => void
  setLoginAndPasswordForRegister: (value: LoginDTO) => void
  register: () => Promise<boolean>
  verifyRegister: (otp: string) => Promise<boolean>
  setLoginAndPasswordForReset: (value: LoginDTO) => void
  resetPassword: () => Promise<boolean>
  verifyResetPassword: (otp: string) => Promise<boolean>
  subscribeToQrIdGenerate: () => () => void
  login: (loginDto: LoginDTO) => Promise<void>
  subscribeToQrPolling: () => () => void
  activateQrPolling: (id: string) => Promise<boolean>
  loadMe: () => Promise<void>
  logout: () => Promise<void>
  setVerified: (value: boolean) => void
  setIsAuth: (value: boolean) => void
  subscribeToFaceId: (
    dataGetter: () => Promise<FaceIdSocketSource>,
    generateReCaptcha: (action?: string) => Promise<string>
  ) => Promise<FaceIdSocketListener | null>
}

export type FaceIdSocketSource = Blob | null