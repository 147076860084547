import { Navigate } from 'react-router-dom';
import useAuthStore from '../store/auth/auth.store';
import { Outlet } from 'react-router-dom';

export default function GuestGuard() {
  const { isAuth } = useAuthStore(state => ({
    isAuth: state.isAuth
  }));

  return isAuth
    ? <Navigate to="/" />
    : <Outlet />;
}
